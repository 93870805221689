import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import ScreenCapture from '@uppy/screen-capture';
import Webcam from '@uppy/webcam';
import React, { useEffect, useMemo, useState } from 'react';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/screen-capture/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import knowledgeService from '../../services/knowledgeService';
import DisplayFiles from '../DisplayingFiles/DisplayFiles';

// Don't forget to keep the Uppy instance outside your component.
function uppyInstance(allTypes, small) {
    const uppy = new Uppy({
        allowMultipleUploadBatches: allTypes, // set dynamically based on allTypes
        autoProceed: small,
        restrictions: {
            maxNumberOfFiles: allTypes ? null : 1, // if allTypes is true, no limit on number of files
            minNumberOfFiles: 1,
            allowedFileTypes: allTypes ? ['*/*'] : ['video/*'],
            maxFileSize: 30000000000 // 30GB
        }
    })
    if (!allTypes) {
        uppy.use(Webcam, {
            showVideoSourceDropdown: true,
            showRecordingLength: true,
            preferredVideoMimeType: 'video/mp4',
            modes: ['video-audio']
        })
        uppy.use(ScreenCapture, {preferredVideoMimeType: 'video/mp4'})
    }
    uppy.use(AwsS3, {
        // shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
        // companionUrl: 'http://127.0.0.1:8000/knowledgebase/api/',
        // companionHeaders: companionHeaders.headers,
        getUploadParameters(file) {
            console.log('Getting upload parameters for file ::', file.name);
            console.log('the file is :::', file);

            let fileType = file.type;
            if (fileType.includes(';')) {
                fileType = fileType.substring(0, fileType.indexOf(';'));
            }

            const data = {
                file_name: file.name,
                file_content_type: fileType
            };

            return knowledgeService.generateS3PresignedUrl(data).then((resData) => {
                // Return an object in the correct shape.
                console.log('Got upload parameters for file', file.name);

                if (resData) {
                    uppy.setFileState(file.id, { uploadUrl: resData.url.substring(0, resData.url.indexOf('?X-Amz-Algorithm')) });
                    return {
                        method: resData.method,
                        url: resData.url,
                        fields: [],
                        expires: resData.expires_in,
                        headers: { 'Content-Type': resData.content_type }
                    };
                }
                return {};
            });
        }
    });
    // .use(GoldenRetriever, { serviceWorker: true });

    return uppy;
}


export default function KBArticleMediaRecorder({ setVideo, small, allTypes, setOnDeck, initialFiles = [] }) {

    if (small) {
        // for some reason adding the small settings to the large compoents breaks making videos
        return <KBArticleMediaRecorderSmall setVideo={setVideo} small={true} allTypes={allTypes} setOnDeck={setOnDeck} initialFiles={initialFiles} />
    } else {
        // for some reason adding the small settings to the large compoents breaks making videos
        return <KBArticleMediaRecorderLarge setVideo={setVideo} small={false} allTypes={allTypes} setOnDeck={setOnDeck} initialFiles={initialFiles} />
    }
}

export function KBArticleMediaRecorderSmall({ setVideo, small, allTypes, setOnDeck, initialFiles = [] }) {
    const uppy = useMemo(() => uppyInstance(allTypes, small), [allTypes, small]);
    const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
    const [showUploader, setShowUploader] = useState(!initialFiles || initialFiles.length === 0);

    uppy.on('file-added', () => {
        console.log('file added');
        typeof setOnDeck === 'function' && setOnDeck(true);
    });

    uppy.on('dashboard:show-panel', () => {
        console.log('file a');
        typeof setOnDeck === 'function' && setOnDeck(true);
    });

    uppy.on('file-removed', () => {
        typeof setOnDeck === 'function' && setOnDeck(false);
    });

    uppy.on('complete', function (result) {
        console.log('complete upload ::::::::', result);
        const urls = result.successful?.map((file) => file.uploadUrl);
        let newFiles = urls;
        
        if (small) {
            newFiles = [...uploadedFiles, ...urls];
            setUploadedFiles(newFiles);
        }
            
        typeof setVideo === 'function' && setVideo(newFiles);
        
        uppy.cancelAll({ reason: 'user' });
        typeof setOnDeck === 'function' && setOnDeck(false);
        if (small) {
            setShowUploader(false);
        }
    });

    const handleDeleteFile = (index) => {
        const newFiles = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles(newFiles);
        typeof setVideo === 'function' && setVideo(newFiles);
        
        // If all files are deleted, show the uploader again
        if (newFiles.length === 0) {
            setShowUploader(true);
        }
    };

    const toggleUploader = () => {
        setShowUploader(!showUploader);
    };

    useEffect(() => {
        // Initialize with any files passed in
        setUploadedFiles(initialFiles || []);
    }, [initialFiles]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register(`${process.env.PUBLIC_URL}/service-worker.js`) // path to your bundled service worker with GoldenRetriever service worker
                .then((registration) => {
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                })
                .catch((error) => {
                    console.log('Registration failed with ' + error);
                });
        }
    }, [uppy]);


    

    return (
        <div className="file-uploader-container">
            {/* File List Display */}
            {small && uploadedFiles.length > 0 && (
                <div className="uploaded-files mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h6 className="mb-0">Uploaded Files</h6>
                        {!showUploader && (
                            <button 
                                type="button" 
                                className="btn btn-sm btn-primary d-flex align-items-center mr-0" 
                                onClick={toggleUploader}
                            >
                               Add File <i className="mr-1 feather icon-plus" /> 
                            </button>
                        )}
                    </div>
                <DisplayFiles uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} callbackFunc={handleDeleteFile} />
                </div>
            )}

            {/* Uploader Dashboard */}
            {showUploader && (
                <div className="uploader-dashboard">
                    <Dashboard
                        {...(small ? { height: 200 } : {})}
                        width="100%" 
                        uppy={uppy}
                        autoProceed={true}
                        plugins={['Webcam', 'ScreenCapture']}
                    />
                    {small && uploadedFiles.length > 0 && (
                        <button 
                            type="button" 
                            className="btn btn-sm btn-secondary mt-2" 
                            onClick={toggleUploader}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export function KBArticleMediaRecorderLarge({ setVideo, small, allTypes, setOnDeck }) {
    const uppy = useMemo(() => uppyInstance(allTypes), [allTypes]);

    uppy.on('file-added', () => {
        console.log('file added');
        typeof setOnDeck === 'function' && setOnDeck(true);
    });

    uppy.on('dashboard:show-panel', () => {
        console.log('file a');
        typeof setOnDeck === 'function' && setOnDeck(true);
    });


    uppy.on('file-removed', () => {
        typeof setOnDeck === 'function' && setOnDeck(false);
    });

    uppy.on('complete', function (result) {
        console.log('complete upload ::::::::', result);
        const urls = result.successful?.map((file) => file.uploadUrl);
        typeof setVideo === 'function' && setVideo(urls);
        uppy.cancelAll({ reason: 'user' });
        typeof setOnDeck === 'function' && setOnDeck(false);
    });

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register(`${process.env.PUBLIC_URL}/service-worker.js`) // path to your bundled service worker with GoldenRetriever service worker
                .then((registration) => {
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                })
                .catch((error) => {
                    console.log('Registration failed with ' + error);
                });
        }
    }, [uppy]);


    return (
        <div style={{ width: '100%' }}>
            <Dashboard
                {...(small ? { height: 200 } : {})}
                width="100%" uppy={uppy}
                autoProceed={true}
                plugins={['Webcam', 'ScreenCapture']}
            />
        </div>
    );
}
