import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/agGrid.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { useDispatch, useSelector } from 'react-redux';
import { BASENAME, DJANGO, changeApiUrl } from './config/constant';
import routes, { renderRoutes } from './routes';
import userService from './services/userService';
import { ACCOUNT_INITIALIZE, LOGOUT } from './store/actions';
import { postToFrontEndErrors } from './services/actionsService';
import debounce from 'lodash.debounce';

// import './App.scss'; // Assuming you have some styles

LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-048786 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Humanagement LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 October 2024 )____[v2]_MTcyNzk5NjQwMDAwMA==e206a710510aa81fbe6a72064432cc87'
);



const App = () => {
    const dispatcher = useDispatch();

    const user = useSelector((state) => state.account.user);

    console.log("software did load")

    console.log('DEBUG: user', user);

    useEffect(() => {
        (async () => {
            try {            
                // Handle redirect if needed
                const currentHost = window.location.origin;
                const referralUrl = user.company.referral_url;
                const currentPath = window.location.pathname;
                                
                if (currentHost === 'https://app.humanagement.io' && 
                    referralUrl && 
                    referralUrl !== currentHost &&
                    !referralUrl.includes(currentHost)) {

                    // Logout userfrom humanagement.io
                    await userService.logoutUser();
                    


                    await new Promise(resolve => setTimeout(resolve, 700));

                    // Create auto-login params with token

                    const loginParams = new URLSearchParams({
                        autoLogin: true,
                        token: user.token,
                        timestamp: Date.now(),
                        redirect: currentPath
                    }).toString();
                    
                    window.location.href = `${referralUrl}/auth/signin/?${loginParams}`;
                    return;
                }

            } catch (error) {
                console.error('Failed to fetch user profile:', error);
            }
        })();
    }, [user, dispatcher]);

    // Load Chargebee JS
    useEffect(() => {
        const charge = document.createElement('script');
        charge.src = 'https://js.chargebee.com/v2/chargebee.js';
        charge.setAttribute('data-cb-site', process.env.REACT_APP_CHARGEBEEURL || 'humanagement-test');
        document.head.appendChild(charge);

        return () => {
            document.head.removeChild(charge);
        };
    }, []);

    function getCircularReplacer() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return '[Circular]';
                }
                seen.add(value);
            }
            return value;
        };
    }

    

//     // Version Check
//     useEffect(() => {
//         const currentVersion = process.env.REACT_APP_VERSION;
// 
//         fetch('/version.json')
//             .then((response) => response.json())
//             .then((data) => {
//                 const latestVersion = data.version;
//                 if (currentVersion !== latestVersion) {
//                     
//                 }
//             })
//             .catch((err) => {
//                 console.error('Failed to fetch version info:', err);
//             });
//     }, []);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.log('ServiceWorker registration successful');
                })
                .catch(err => {
                    console.log('ServiceWorker registration failed: ', err);
                });
        }
    }, []);

    return (
        <React.Fragment>
            <Router basename={BASENAME}>{renderRoutes(routes())}</Router>
            <ToastContainer stacked />
        </React.Fragment>
    );
};

export default App;
