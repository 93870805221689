import axios from 'axios';
import { toast } from 'react-toastify';
import { API_SERVER } from '../config/constant';
import { buildCompanyFormData } from '../utils/common';
import { createTree, formatManager } from '../utils/formatManager';
import http, { serializeQuery, showAxiosErrorNotification } from './httpService';

const getCompany = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}company/api/detail-update/${id}/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const deleteCompany = async (id) => {
    try {
        await http.delete(`${API_SERVER}company/api/delete/${id}/`, http.tokenConfig());
        return 'success';
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getAllCompanies = async (query) => {
    // pass query as a dict e.g. {allow_related: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}company/api/all-companies/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getCompanyWeekending = async (id) => {
    const data = await getCompany(id);
    return data?.company_weekend_info;
};

const getCompanyUsers = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/staffs/company_users/`, http.tokenConfig());
        return createTree(formatManager(data));
    } catch (error) {
        console.log(error);
        return false;
    }
};

const updateCompany = async (id, data) => {
    const formData = new FormData();
    buildCompanyFormData(formData, data);

    try {
        const { data } = await http.patch(`${API_SERVER}company/api/detail-update/${id}/`, formData, http.multiPartTokenConfig());
        return data;
    } catch (error) {
        toast.error(JSON.stringify(error?.response?.data));
        console.log(error);
        return false;
    }
};

const clockAllUsersOut = async (formData) => {
    try {
        const { data } = await http.post(`${API_SERVER}inout/api/clock-all-users-out/`, formData, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getCompanyStaff = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/staffs/company_users/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getCompanyActiveFeatures = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}company/api/active-apps/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getCurrencies = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}company/api/currencies/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getHumeSignToken = async (id, url, name) => {
    try {
        let params = new URLSearchParams();
        if (id) params.append('id', id);
        if (url) params.append('url', url);
        if (name) params.append('name', name);

        const { data } = await http.get(`${API_SERVER}company/api/get_hume_sign_token/?${params.toString()}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getHumeSignDoc = async (submissionId, type, userId) => {
    try {
        const { data } = await http.get(
            `${API_SERVER}company/api/get_hume_sign_doc/?submission=${submissionId}&type=${type}&user=${userId}`,
            http.tokenConfig()
        );
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getHRFileTypes = async (queryDict) => {
    const queryParams = queryDict ? serializeQuery(queryDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}company/api/file-types/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const createHRFileType = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}company/api/file-types/`, data, http.tokenConfig());
        toast.success('Course created successfully!');
        return res.data;
    } catch (err) {
        // toast.error('Course Create Error ' + JSON.stringify(err.response.data));
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

const getHRFileTypeDetails = async (id) => {
    try {
        const response = await http.get(`${API_SERVER}company/api/file-types/${id}/`, http.tokenConfig());
        return response.data;
    } catch (error) {
        toast.error(JSON.stringify(error.response.data));
        console.log(error);
        return false;
    }
};
const patchHRFileType = async (id, data) => {
    try {
        const response = await http.patch(`${API_SERVER}company/api/file-types/${id}/`, data, http.tokenConfig());
        return response.data;
    } catch (error) {
        toast.error(JSON.stringify(error.response.data));
        console.log(error);
        return false;
    }
};

const deleteHRFileType = async (id) => {
    try {
        await axios.delete(`${API_SERVER}company/api/file-types/${id}/`, http.tokenConfig());
        toast.warning('Article Deleted Successfully!');
        return 'success';
    } catch (err) {
        toast.error('An error occurred while deleting file type ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting file type >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getOrgFlow = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}company/api/orgflow/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const patchOrgFlow = async (data) => {
    try {
        const { res } = await http.patch(`${API_SERVER}company/api/orgflow/`, data, http.tokenConfig());
        return res;
    } catch (error) {
        toast.error(JSON.stringify(error.response.data));
        console.log(error);
        return false;
    }
};

const getHorizontalOrg = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}company/api/horizontalorg/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const patchHorizontalOrg = async (data) => {
    try {
        const { res } = await http.patch(`${API_SERVER}company/api/horizontalorg/`, data, http.tokenConfig());
        return res;
    } catch (error) {
        toast.error(JSON.stringify(error.response.data));
        console.log(error);
        return false;
    }
};

const getOpenAIProxy = async (body) => {
    try {
        const res = await http.post(`${API_SERVER}company/api/openai-proxy/`, body, http.tokenConfig());
        console.log("[getOpenAIProxy] res", res);
        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
};




export default {
    getCompany,
    deleteCompany,
    getCompanyWeekending,
    getCompanyUsers,
    updateCompany,
    getCompanyStaff,
    getCompanyActiveFeatures,
    clockAllUsersOut,
    getCurrencies,
    getAllCompanies,
    getOrgFlow,
    patchOrgFlow,
    getHorizontalOrg,
    patchHorizontalOrg,
    getHumeSignToken,
    getHumeSignDoc,
    getHRFileTypes,
    createHRFileType,
    getHRFileTypeDetails,
    patchHRFileType,
    deleteHRFileType,
    getOpenAIProxy
};
