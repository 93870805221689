import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { deletePosition, getAllUsersSimple, getPositions } from '../../services/userService';
import { SET_POSITIONS, UPDATE_ORG_CHART, UPDATE_ORG_CHART_POSITION } from '../../store/actions';
import { formatToReactSelectOption, sortListOfObjectsAlphabetically } from '../../utils/common';
import ArticlesMulltiSelectInput from '../../views/forms/custom-fields/ArticlesMulltiSelectInput';
import DivisionInput from '../../views/forms/custom-fields/DivisionInput';
import ResponsibilitiesInput from '../../views/forms/custom-fields/ResponsibilitiesInput';
import IntlMessages from "../../views/checkLists/Components/IntlMessages";
import RichTextEditor from "../Input/RichTextEditor";
import useLocalStorage from '../../hooks/useLocalStorage';

export default function CreatePositionForm({ handleSubmit, instance, handleClose, type, orgFlow = false, }) {
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [positions, setPositions] = useState([]);
    const [positionsLoading, setPositionsLoading] = useState(false);
    const [users, setUsers] = useState({ data: [], loading: true });
    // const { positions } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);
    const updateOrgChart = useSelector((state) => state.companyReducer.updateOrgChart);
    const [showHeader, setShowHeader] = useLocalStorage('showPositionHeader', false);
    const [showDescription, setShowDescription] = useLocalStorage('showPositionDescription', false);

    const loaders = [positionsLoading, users.loading];
    const [createPositionData, setCreatePositionData] = useState({
        name: '',
        division: '',
        manager: '',
        users: [],
        order: 1,
        division_order: 1,
        responsibilities: [],
        articles: []
    });
    const [postionOrderOptions, setPostionOrderOptions] = useState([]);

    const handleInputChange = (e) => {
        // Handles the change of form inputs other than react-select inputs.
        const name = e.target.name;
        const value = e.target.value;
        setCreatePositionData({ ...createPositionData, [name]: value });
    };

    const handleReactSelectInputChange = (data, options) => {
        // Handles the change of the react select input values.
        const { name } = options;

        setCreatePositionData({ ...createPositionData, [name]: data });
    };

    const getListOfNumbers = (data) => {
        // returns a list of numbers for react select input which
        // this list is required by the backend.
        let values = [];
        data?.map((item) => {
            values.push(item.value);
        });
        return values;
    };

    const cleanPostData = () => {
        // gets the data to submit to the server
        const cleanUsers = getListOfNumbers(createPositionData.users);
        const cleanResponsibilities = getListOfNumbers(createPositionData.responsibilities);
        const cleanArticles = getListOfNumbers(createPositionData.articles);
        const data = { ...createPositionData, users: cleanUsers, responsibilities: cleanResponsibilities, articles: cleanArticles };
        return data;
    };


    const onSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setSubmitting(true);
            // Form is valid, submit to the server
            if (typeof handleSubmit === 'function') {
                const data = cleanPostData();

                const { res_data } = await handleSubmit(data);
                if (res_data) {
                    setSubmitting(false);
                } else {
                    setSubmitting(false);
                }
            }
        }

        setValidated(true);
    };

    const fetchPositions = async () => {
        setPositionsLoading(true);
        const data = await getPositions({ simple_details: true });
        if (data) {
            // dispatch({ type: SET_POSITIONS, payload: data });
            setPositions(data);
            setPositionsLoading(false);
        }
    };

    const fetchEmployees = async () => {
        if (users.data.length === 0) {
            setUsers({ ...users, loading: true });
            const data = await getAllUsersSimple();
            if (data) {
                const formattedData = formatToReactSelectOption(data, 'get_full_name');
                setUsers({ data: formattedData, loading: false });
            }
        }
    };

    const updatePositionOrderOptions = () => {
        // When a division is changed, we get the order for
        // positions in that division.
        let orderOptionsCount = 1;
        if (positions) {
            orderOptionsCount = positions.length || 1;
        }
        const orderOptions = Array.from({ length: orderOptionsCount + 1 }, (_, index) => index + 1);
        setPostionOrderOptions(orderOptions);
    };

    const handleDeletePosition = async () => {
        console.log("handleDeletePosition injectItem")
        setDeleting(true);
        const data = await deletePosition(instance.id);
        if (data) {
            console.log("handleDeletePosition injectItem")
            const newPositions = positions.filter((position) => position.id !== instance.id);
            dispatch({ type: SET_POSITIONS, payload: newPositions });
            dispatch({ type: UPDATE_ORG_CHART_POSITION, payload: data })
            // dispatch({ type: UPDATE_ORG_CHART, payload: !updateOrgChart });
            setDeleting(false);

            setShowConfirm(false);
            handleClose();
        }
    };

    useEffect(() => {
        updatePositionOrderOptions();
    }, [positions]);

    useEffect(() => {
        fetchEmployees();
        fetchPositions();
    }, []);

    useEffect(() => {
        // set initial form data if there is an instance passed as prop

        if (instance) {
            const usersInitial = instance.position_staff ? formatToReactSelectOption(instance.position_staff, 'get_full_name') : [];
            const articlesInitial = instance.position_articles ? formatToReactSelectOption(instance.position_articles, 'title') : [];
            const responsibilitiesInitial = instance.position_responsibilities
                ? formatToReactSelectOption(instance.position_responsibilities, 'title')
                : [];
            const initialData = {
                name: instance.name ||  '',
                division: instance.division || '',
                manager: instance.manager || '',
                description: instance.description || '',
                above_text: instance.above_text || '',
                users: usersInitial,
                responsibilities: responsibilitiesInitial,
                articles: articlesInitial,
                order: instance.order,
                division_order: instance.division_order
            };
            setCreatePositionData(initialData);
        }
    }, []);

    const handleToggle = (field) => {
        if (field === 'header') {
            const newValue = !showHeader;
            setShowHeader(newValue);
        } else if (field === 'description') {
            const newValue = !showDescription;
            setShowDescription(newValue);
        }
    };

    return (
        <Form noValidate validated={validated}>
            <Form.Group controlId="validatePositionName">
                <Form.Label>Position Name</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control
                        type="text"
                        placeholder="Enter position name..."
                        name="name"
                        value={createPositionData.name}
                        onChange={handleInputChange}
                        required
                    />
                    <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Please enter position name.</Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            {type !== 'simple' && (
                <>
                    <DivisionInput
                        selected={createPositionData.division}
                        handleInputChange={handleInputChange}
                        hasValidation={false}
                        required={true}
                    />

                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Managing Position</Form.Label>
                        <Form.Control
                            as="select"
                            disabled={positionsLoading}
                            name="manager"
                            value={createPositionData.manager}
                            onChange={handleInputChange}
                        >
                            {positionsLoading ? (
                                <option>Loading...</option>
                            ) : (
                                <>
                                    <option value="">-------------</option>
                                    {positions &&
                                        sortListOfObjectsAlphabetically(positions, 'name')?.map((pos) => {
                                            return (
                                                <option key={pos.id} value={pos.id}>
                                                    {pos.name}
                                                </option>
                                            );
                                        })}
                                </>
                            )}
                        </Form.Control>
                    </Form.Group>

                    {type === 'orgFlow' && <>
                        <div className="form-group">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label className="control-label mb-0">Header</label>
                                <Form.Check 
                                    type="switch"
                                    id="header-switch"
                                    checked={showHeader}
                                    onChange={() => handleToggle('header')}
                                />
                            </div>
                            {(showHeader || instance?.above_text) && (
                                <Form.Group controlId="above-text">
                                    <RichTextEditor 
                                        simple={true} 
                                        placeholder={'Add Any Text Here'} 
                                        value={createPositionData.above_text} 
                                        onChange={(value)=>setCreatePositionData({...createPositionData, above_text: value})} 
                                    />
                                </Form.Group>
                            )}
                        </div>

                        <div className="form-group">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label className="control-label mb-0">Description</label>
                                <Form.Check 
                                    type="switch"
                                    id="description-switch"
                                    checked={showDescription}
                                    onChange={() => handleToggle('description')}
                                />
                            </div>
                            {(showDescription || instance?.description) && (
                                <Form.Group controlId="description">
                                    <RichTextEditor 
                                        simple={true} 
                                        placeholder={'Add Any Text Here'} 
                                        value={createPositionData.description} 
                                        onChange={(value)=>setCreatePositionData({...createPositionData, description: value})} 
                                    />
                                </Form.Group>
                            )}
                        </div>
                    </>}

                    {type === 'orgPos' &&<>

                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Order</Form.Label>
                        <Form.Control
                            as="select"
                            disabled={positionsLoading}
                            name="order"
                            value={createPositionData.order}
                            onChange={handleInputChange}
                        >
                            {positionsLoading ? (
                                <option>Loading...</option>
                            ) : (
                                <>
                                    <option value="">-------------</option>
                                    {positions &&
                                        postionOrderOptions?.map((order) => {
                                            return (
                                                <option key={order} value={order}>
                                                    {order}
                                                </option>
                                            );
                                        })}
                                </>
                            )}
                        </Form.Control>
                        <Form.Text id="orderHelpText" muted>
                            Determines whether it is to the right or left of follow juniors
                        </Form.Text>
                    </Form.Group>     </>}

                    <Form.Group>
                        <Form.Label>Users</Form.Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={createPositionData.users}
                            value={createPositionData.users}
                            isDisabled={users.loading}
                            isLoading={users.loading}
                            placeholder={users.loading ? 'loading...' : 'Select Position staff'}
                            isClearable={true}
                            isMulti
                            isSearchable={true}
                            name="users"
                            onChange={handleReactSelectInputChange}
                            options={users.data}
                        />
                    </Form.Group>
                    <ResponsibilitiesInput
                        value={createPositionData.responsibilities}
                        defaultValue={createPositionData.responsibilities}
                        handleInputChange={handleReactSelectInputChange}
                    />

                    <ArticlesMulltiSelectInput
                        value={createPositionData.articles}
                        defaultValue={createPositionData.articles}
                        handleInputChange={handleReactSelectInputChange}
                    />
                </>
            )}

            <Button
                variant="primary"
                onClick={onSubmit}
                className="float-right mr-0 mt-3"
                disabled={loaders.some((item) => item === true) || submitting}
            >
                {submitting ? 'Submitting...' : instance ? 'Update Position' : 'Create Position'}
            </Button>
            {/*do a confirmation modal*/}
            {instance && (
                <Button
                    variant="danger"
                    className="float-right mt-3"
                    // disabled={loaders.some((item) => item === true) || submitting}
                    onClick={() => setShowConfirm(true)}
                >
                    {submitting ? 'Submitting...' : 'Delete'}
                </Button>
            )}
            <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this position?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirm(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeletePosition();
                            // dispatch({ type: UPDATE_ORG_CHART, payload: !updateOrgChart });
                            handleClose && handleClose();
                            setShowConfirm(false);
                        }}
                        disabled={deleting}
                    >
                        {deleting ? 'Deleting...' : 'Delete'}
                    </Button>df
                </Modal.Footer>
            </Modal>
        </Form>
    );
}
