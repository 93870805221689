import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import userService from '../../../services/userService';
import { formatToReactSelectOption } from '../../../utils/common';
import CreatePositionModal from '../../../components/Positions/CreatePositionModal';

export default function PositionMultiSelectInput({ value, defaultValue, handleInputChange, label, name, canAdd = true }) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(value);

    const fetchOptionsData = async () => {
        if (options.length === 0) {
            setLoading(true);
            const data = await userService.getPositions({ simple_details: true });
            if (data) {
                const formattedData = formatToReactSelectOption(data, 'name');
                setOptions(formattedData);
                setLoading(false);
            }
        }
    };

    const handleChange = (data, options) => {
        if (typeof handleInputChange === 'function') {
            handleInputChange(data, options);
        }
        setSelected(data);
    };

    const updatePositions = (data) => {
        const formattedData = formatToReactSelectOption([data], 'name');
        const updatedOptions = [...formattedData, ...options];
        setOptions(updatedOptions);
        
        // Add the new position to the current selection
        const newSelection = selected ? [...selected, formattedData[0]] : formattedData;
        setSelected(newSelection);
        
        // Trigger handleInputChange with the updated selection
        if (typeof handleInputChange === 'function') {
            handleInputChange(newSelection, { action: 'select-option', option: formattedData[0] });
        }
    };

    useEffect(() => {
        if (value && options.length > 0) {
            const selectedOptions = options.filter((opt) => value.includes(opt.id));
            setSelected(selectedOptions);
        }
    }, [value, options.length]);

    useEffect(() => {
        fetchOptionsData();
    }, []);

    return (
        <Form.Group>
            <Form.Label>{label || 'Positions'}</Form.Label>
            <div className="d-flex">
                <Select
                    className="basic-single w-100"
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    value={selected}
                    isDisabled={loading}
                    isLoading={loading}
                    placeholder={loading ? 'loading...' : 'Select Positions'}
                    isClearable={true}
                    isMulti
                    isSearchable={true}
                    name={name || 'positions'}
                    onChange={handleChange}
                    options={options}
                />
                {canAdd && <CreatePositionModal callbackFunc={updatePositions} type={'simple'} className={'btn-primary'} />}
            </div>
        </Form.Group>
    );
}
