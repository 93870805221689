import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaPencil } from 'react-icons/fa6';
import CustomTooltip from '../../../CustomTooltip';
import CategoryForm from '../../Forms/CategoryForm';

export default function UpdateCategoryModal({ category, onUpdateSuccess, showDeleteButton, onDeleteSuccess, icon, categories }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUpdateSuccess = async (data) => {
        typeof onUpdateSuccess === 'function' && (await onUpdateSuccess(data));
        handleClose();
    };

    const handleDeleteSuccess = async (categoryId) => {
        typeof onDeleteSuccess === 'function' && (await onDeleteSuccess(categoryId));
        handleClose();
    };

    return (
        <>
            <CustomTooltip title="Edit Article Category">
                <Button
                    variant={'primary'}
                    className={icon? "btn btn-sm btn-none py-0 my-n3 pl-2" :"btn btn-sm btn-icon btn-primary mx-0"}
                    onClick={handleShow}
                    style={{ height: '42.8px' }}
                >
                    {icon || <FaPencil />}
                </Button>
            </CustomTooltip>

            {show && <Modal backdrop="static" enforceFocus={false} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Category: {category.category}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategoryForm instance={category} categories={categories} onSubmitSuccess={handleUpdateSuccess} showDeleteButton={showDeleteButton} onDeleteSuccess={handleDeleteSuccess} loadedCategories={categories} />
                </Modal.Body>
            </Modal>}
        </>
    );
}
