import {useState} from "react";

export default function useLocalStorageCustom(key, initialValue) {
    // Function to check if a string is a valid date format
    const isDateString = (value) => {
        return !isNaN(Date.parse(value));
    };

    // Get item from local storage and parse it
    const getItem = (key) => {
        const storedValue = localStorage.getItem(key);
        try {
            const parsedValue = JSON.parse(storedValue);
            Object.keys(parsedValue).forEach(k => {
                if (typeof parsedValue[k] === 'string' && isDateString(parsedValue[k])) {
                    parsedValue[k] = new Date(parsedValue[k]);
                }
            });
            return parsedValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    };

    // Set item to local storage
    const setItem = (key, value) => {
        try {
            const valueToStore = JSON.stringify(value, (k, v) => {
                return v instanceof Date ? v.toISOString() : v;
            });
            localStorage.setItem(key, valueToStore);
        } catch (error) {
            console.log(error);
        }
    };

    // useState hook to manage the state
    const [storedValue, setStoredValue] = useState(() => getItem(key) || initialValue);

    // Setter function that handles both Date and non-Date values
    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            setItem(key, valueToStore);
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}