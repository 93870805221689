import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';

import { BiFile, BiTrash, BiVideoPlus } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { BigPlayButton, ControlBar, ForwardControl, PlaybackRateMenuButton, Player, ReplayControl } from 'video-react';
import '../../../assets/css/video-react.css'; // import css
import CustomTooltip from '../../../components/CustomTooltip';
import RenderFiles from '../../../components/DisplayingFiles/RenderFiles';
import TagsInput from '../../../components/Input/TagsInput';
import RichTextEditor from '../../../components/Input/RichTextEditor';
import { ArticleCategorySelect } from '../../../components/KnowledgeBase/ArticleCategorySelect';
import GenerateKBAIModal from '../../../components/KnowledgeBase/GenerateKBAI';
import ArticleTypeInput from '../../../components/KnowledgeBase/Input/ArticleTypeInput';
import ArticleVisibilityInput from '../../../components/KnowledgeBase/Input/ArticleVisibilityInput';
import KBArticleMediaRecorder from '../../../components/KnowledgeBase/KBArticleMediaRecorder';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { Knowledge } from '../../../services';
import { formatToReactSelectOption } from '../../../utils/common';
import NotFound404 from '../../errors/NotFound404';
import DivisionMultiSelectInput from '../../forms/custom-fields/DivisionsMultiSelectInput';
import KBChallengeBuilderSelect from '../../forms/custom-fields/KBChallengeSelect';
import PositionMultiSelectInput from '../../forms/custom-fields/PositionsMultiSelectInput';
import ResponsibilitiesInput from '../../forms/custom-fields/ResponsibilitiesInput';
import UsersMultiSelectInput from '../../forms/custom-fields/UsersMultiSelectInput';
import FormSkeletonLoader from '../Loaders/FormSkeletonLoader';
import ArticleFlow from './ArticleFlow';
import useLocalStorageCustom from '../../../components/Helpers/useLocalStorageCustom';

export const SAVE_AND_ADD_ANOTHER_ARTICLE = 1;

export default function ArticleForm({ setReload, callbackFunc, articleId, editArticle, isCourseArticle, isDraft }) {
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState({});
    const [onDeck, setOnDeck] = useState(false);
    const [submitting, setSumbitting] = useState(false);
    const [showVideoOptions, setShowVideoOptions] = useState(false);
    const [showFileOptions, setShowFileOptions] = useState(false);

    const history = useHistory();
    const { user_is_kb_admin } = useSelector((state) => state.account.user.user_roles);
    const [showAdvancedForm, setShowAdvancedForm] = useLocalStorage('articleShowAdvancedForm', false);

    const [article, setArticle] = useLocalStorageCustom('article form' + articleId, {
        article_category: false,
        body: '',
        visibility: 1,
        title: '',
        type: '',
        tags: [],
        video_url: null,
        assigned_responsibilities: [],
        file_url: null,
        is_assigned_to_everyone: false,
        reassign_month_duration: 0,
        file_urls: [],
        public_knowledgebases: [],
        decision_chart: null
    },);

    console.log('DEBUG: article', article);

    const [showDecisionChart, setShowDecisionChart] = useState(false);

    const articleReassignDurations = [
        { value: 0, label: 'Never' },
        { value: 1, label: '1 Month' },
        { value: 2, label: '2 Months' },
        { value: 3, label: '3 Months' },
        { value: 4, label: '4 Months' },
        { value: 5, label: '5 Months' },
        { value: 6, label: '6 Months' },
        { value: 7, label: '7 Months' },
        { value: 8, label: '8 Months' },
        { value: 9, label: '9 Months' },
        { value: 10, label: '10 Months' },
        { value: 11, label: '11 Months' },
        { value: 12, label: '12 Months' }
    ];

    const [originalArticle, setOriginalArticle] = useState({});

    useEffect(async () => {
        if (editArticle&&article.body=='') {
            setIsError(false);
            setLoading(true);

            let data;
            if (isDraft) {
                data = await Knowledge.getDraftArticleDetails(articleId, true);
            } else {
                data = await Knowledge.fetchArticle(articleId, true);
            }
            if (data.response) {
                if (data.response?.status === 404) {
                    setIsError(true);
                    setLoading(false);
                }
            } else {
                setOriginalArticle(data);

                setArticle({
                    ...data,
                    assigned_responsibilities: formatToReactSelectOption(data.assigned_responsibilities_info || [], 'title')
                });
                setShowDecisionChart(data.decision_chart && data.decision_chart.edges && data.decision_chart.nodes);
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }, [articleId]);

    const updateContent = (value) => {
        // setBody(prev => !prev);
        setArticle((article) => ({ ...article, body: value }));
    };

    const handleChange = (e, isCheckBoxInput) => {
        // function to handle the change of form inputs
        const name = e.target.name;
        const value = isCheckBoxInput ? e.target.checked : e.target.value;
        setArticle((article) => ({ ...article, [name]: value }));
    };

    const handleTagsChange = (tags) => {
        setArticle((article) => ({ ...article, tags: tags.length > 0 ? tags?.map((tag) => tag.name) : [] }));
    };

    const handleReactSelectMultiInputChange = (data, options) => {
        const name = options.name;
        const values = name === 'assigned_responsibilities' ? data : data?.map((item) => item.id);
        setArticle((article) => ({ ...article, [name]: values }));
    };

    const handleKBChallengeChange = (data) => {
        setArticle((article) => ({ ...article, ...data }));
    };

    const validateForm = () => {
        let newErrors = {};

        // Validate title
        if (!article.title.trim()) {
            toast.error('please add an Article Title');
            newErrors.title = 'Title is required.';
        }

        // Validate category
        if (!article.article_category) {
            toast.error('Please Add an Article Category');
            newErrors.article_category = 'Category is required.';
        }

        setErrors(newErrors);

        // If there are no errors, proceed with form submission
        if (Object.keys(newErrors).length > 0) {
            return false;
        }

        if (article.acknowledgement_type === 2) {
            if (!article.acknowledgement_question || article.acknowledgement_question === '') {
                toast.error('please supply question for Question answer acknowledgement');
                return false;
            }
            if (article.article_answers?.find((ans) => ans.answer === '')) {
                toast.error('You cannot have a question answer as blank, you can either remove it or supply value');
                return false;
            }

            if (!article.article_answers?.some((ans) => ans.is_correct === true)) {
                toast.error('At least one answer should be correct');
                return false;
            }
        }
        return true;
    };

    const getCleanedPostData = (e) => {
        const nodes = JSON.parse(localStorage.getItem('articleNodes'));
        const edges = JSON.parse(localStorage.getItem('articleEdge'));

        let changedFields = {};

        if (editArticle) {
            Object.keys(article).forEach((key) => {
                if (Array.isArray(article[key])) {
                    if (JSON.stringify(article[key]) !== JSON.stringify(originalArticle[key])) {
                        changedFields[key] = article[key];
                    }
                } else if (article[key] !== originalArticle[key]) {
                    changedFields[key] = article[key];
                }
            });
        } else {
            changedFields = { ...article };
        }

        let cleanedData = {
            ...changedFields,
            status: e.target.getAttribute('data-status'),
            dont_notify: e.target.name === 'dont_notify'
        };

        if (showDecisionChart && nodes && edges) {
            cleanedData.decision_chart = { nodes, edges };
        }

        if (cleanedData.assigned_responsibilities) {
            cleanedData.assigned_responsibilities = cleanedData.assigned_responsibilities.map((res) => res.id);
        }

        return cleanedData;
    };

    const draftArticleRedirect = async (id) => {
        // select the draft articles tab to make it active before redirect
        localStorage.setItem('selectedKbTab', 'draft');
        history.push('/knowledgebase/articles/' + id + '/draft');
    };

    const handleArticleSubmitSuccess = (articleData) => {
        const isDraftArticle = articleData.status == 1;
        const articleDataId = articleData?.id;

        // Get the submitType from the body, submit type choices are SAVE_AS_DRAFT, SAVE_AND_PUBLISH, SAVE_AND_ADD_ANOTHER_ARTICLE
        const submitType = articleData.submitType;

        const articleFullFormPages = [
            '/knowledgebase/articles/create',
            `/knowledgebase/articles/${articleDataId}/edit`,
            `/knowledgebase/articles/${articleId}/draft` // For draft article, we use draft article ID.
        ];

        // If the current url path is in the articleFullFormPages array, we redirect to the article detail page
        if (articleFullFormPages.includes(history.location.pathname)) {
            if (submitType === SAVE_AND_ADD_ANOTHER_ARTICLE) {
                // If the submitType is SAVE_AND_ADD_ANOTHER_ARTICLE, we reset the form and show a success message
            } else {
                // We do the redirection only if the submitType is not SAVE_AND_ADD_ANOTHER_ARTICLE
                if (isDraftArticle) {
                    draftArticleRedirect(articleDataId);
                } else {
                    if (!isCourseArticle) {
                        // typeof setReload === 'function' && setReload(articleId);
                        history.push(`/knowledgebase/articles/${articleDataId}`);
                    }
                }
            }
        }

        typeof callbackFunc === 'function' && callbackFunc(articleData);
        typeof setReload === 'function' && setReload((prev) => !prev);
    };

    const handleCreateArticle = async (body) => {
        const isDraftArticle = body.status == 1;
        const submitType = body.submitType;
        delete body.submitType;

        let data;
        if (isDraftArticle) {
            data = await Knowledge.createDraftArticle(body);
        } else {
            data = await Knowledge.postArticle(body);
        }
        if (data?.title) {
            handleArticleSubmitSuccess({ ...data, submitType });
        } else {
            toast.warning('Article did not save please copy this article to a separate document and contact support');
        }
        return data;
    };

    const handleUpdateArticle = async (body) => {
        if (isDraft) {
            const newArticle = { ...body, status: body.status == 0 ? 0 : 1 };
            const data = await Knowledge.updateDraftArticle(articleId, newArticle);
            if (Object.keys(data).length > 0) {
                handleArticleSubmitSuccess(data);
            }
        } else {
            if (body.status == 1) {
                // if now an existing article is being updated to draft status, we create a new
                // draft article.
                await handleCreateArticle(body).then(async (res) => {
                    await draftArticleRedirect(res?.id);
                });
            } else {
                // update the published article the normal way, removing the draft status (status = 1)
                const updateData = {
                    ...body,
                    status: 0
                };
                const data = await Knowledge.patchArticle(articleId, updateData);

                if (Object.keys(data).length > 0) {
                    handleArticleSubmitSuccess({ ...data, ...updateData });
                }
            }
        }
    };

    const deleteArticle = async () => {
        Swal.fire({
            title: `Are you sure you want to delete this ${isDraft ? 'Draft Article' : 'Article'}?`,
            text: "You won't be able to undo this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data;
                if (isDraft) {
                    data = await Knowledge.deleteDraftArticle(articleId);
                } else {
                    data = await Knowledge.deleteArticle(articleId);
                }

                if (data) {
                    !isCourseArticle && history.push('/knowledgebase/articles');
                }
            }
        });
    };

    const closeArticle = async () => {
        // Clear localStorage after successful save
        localStorage.removeItem('article form' + articleId);
        !isCourseArticle
            ? articleId
                ? history.push('/knowledgebase/articles/' + articleId)
                : history.push('/knowledgebase/articles')
            : typeof setReload === 'function' && setReload((prev) => !prev);
    };

    const handleArticleFormSubmit = async (e) => {
        setSumbitting(true);
        const body = getCleanedPostData(e);

        const isValid = validateForm();
        if (isValid) {
            editArticle ? await handleUpdateArticle(body) : await handleCreateArticle(body);
            // Clear localStorage after successful save
            localStorage.removeItem('article form' + articleId);
        }
        localStorage.removeItem('articleNodes');
        localStorage.removeItem('articleEdge');
        setSumbitting(false);
    };

    const handleSaveAndAddAnother = async (e) => {
        // New function to handle "Save & Add Another", used in creating article only
        setSumbitting(true);
        let body = getCleanedPostData(e);
        body['submitType'] = SAVE_AND_ADD_ANOTHER_ARTICLE; // Add a submitType to the body to indicate the action
        const isValid = validateForm();
        if (isValid) {
            const data = await handleCreateArticle(body);
            if (data) {
                const { article_category, type, visibility, assigned_responsibilities, reassign_month_duration } = article;
                setArticle({
                    ...article,
                    body: '',
                    title: '',
                    video_url: null,
                    file_url: null,
                    file_urls: [],
                    public_knowledgebases: [],
                    decision_chart: null
                });
                // Clear localStorage after successful save
                localStorage.removeItem('article form' + articleId);
                toast.success('Form Reset');
            }
        }
        localStorage.removeItem('articleNodes');
        localStorage.removeItem('articleEdge');
        setSumbitting(false);
    };

    const handleSetArticleVideo = (videoUrl) => {
        const urlToSet = Array.isArray(videoUrl) ? videoUrl[0] : videoUrl;
        setArticle({
            ...article,
            video_url: urlToSet
        });
        setShowVideoOptions(false);
    };

    const handleRemoveVideo = () => {
        setArticle({
            ...article,
            video_url: ''
        });
        setShowVideoOptions(false);
    };

    const handleSetArticleFile = (fileUrls) => {
        setArticle({
            ...article,
            file_urls: fileUrls
        });
        setShowFileOptions(false);
    };

    const handleRemoveFile = () => {
        setArticle({
            ...article,
            file_url: '',
            file_urls: []
        });
        setShowFileOptions(false);
    };

    const extension = (file) => {
        return file?.split('.').pop().toLowerCase();
    };

    const formActionButtons = (
        <>
            <CustomTooltip title="Return to articles view and do not save">
                <Button className="btn-secondary" onClick={closeArticle}>
                    Cancel
                </Button>
            </CustomTooltip>
            <DropdownButton
                className="text-capitalize p-0 mr-n2"
                title={onDeck ? 'Please Upload or Cancel Before Saving' : submitting ? 'Submitting...' : 'Save'}
                variant={'primary'}
                id={`save`}
                disabled={submitting || onDeck}
                key={2}
            >
                <CustomTooltip title="Save as a draft, only other admins will be able to see it, until it's published">
                    <Dropdown.Item name="draft" data-status={1} onClick={handleArticleFormSubmit}>
                        Save As Draft
                    </Dropdown.Item>
                </CustomTooltip>
                <CustomTooltip
                    title={
                        'Save and Publish this to the Knowledge Base' +
                        (editArticle ? ' (this will also notify and reassign it to all employees who have read it before)' : '')
                    }
                >
                    <Dropdown.Item name="Publish" data-status={0} onClick={handleArticleFormSubmit} eventKey="2">
                        {editArticle ? (isDraft ? 'Save & Publish' : 'Update & Notify') : 'Save & Publish'}
                    </Dropdown.Item>
                </CustomTooltip>
                {editArticle && !isDraft && (
                    <CustomTooltip
                        title={
                            'Save and Publish this to the Knowledge Base' +
                            (editArticle ? ' (this will not trigger reassignment or update change time)' : '')
                        }
                    >
                        <Dropdown.Item data-status={0} name="dont_notify" onClick={handleArticleFormSubmit} eventKey="3">
                            Update & Don't Notify
                        </Dropdown.Item>
                    </CustomTooltip>
                )}
                {!editArticle && (
                    <CustomTooltip title="Save the current article and start a new one with the same settings">
                        <Dropdown.Item data-status={0} onClick={handleSaveAndAddAnother}>
                            Save & Add Another
                        </Dropdown.Item>
                    </CustomTooltip>
                )}
            </DropdownButton>
        </>
    );

    return (
        <>
            <Card.Header>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-inline-flex ">
                        <h5 className="mt-2">{editArticle ? (isDraft ? 'Edit Draft Article' : 'Edit Article') : 'Create Article'}</h5>
                        {!editArticle && (
                            <div className="">
                                <GenerateKBAIModal setKBBody={(data) => setArticle({ ...article, body: data })} />
                            </div>
                        )}
                        <div className="mt-n2">
                            <label id="normal" className="mr-1">
                                Basic
                            </label>
                            <div className="switch d-inline m-r-10">
                                <input className="ml-5 app_activator" type="checkbox" checked={showAdvancedForm} name="full_form" />
                                <label htmlFor="full_form" className="cr" onClick={() => setShowAdvancedForm(!showAdvancedForm)} />
                            </div>
                            <label>More Options (Assigning)</label>
                        </div>
                    </div>

                    {!isError && !loading && (
                        <Form.Group controlId="courseBody" className="float-right">
                            <div className="d-flex">{formActionButtons}</div>
                        </Form.Group>
                    )}
                </div>
            </Card.Header>
            <Card.Body>
                {loading ? (
                    <FormSkeletonLoader />
                ) : isError ? (
                    <NotFound404 />
                ) : (
                    <div>
                        <Row>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="articleName">
                                    <Form.Label>Article Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={article.title}
                                        name="title"
                                        placeholder="Enter an article name"
                                        onChange={handleChange}
                                        isInvalid={!!errors?.title && article.title === ''}
                                    />
                                    <Form.Control.Feedback type="invalid">A title is required</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12}>
                                <ArticleCategorySelect article={article} setArticle={setArticle} errors={errors} />
                            </Col>
                            {showAdvancedForm && (
                                <>
                                    <Col md={3} sm={12}>
                                        <ArticleTypeInput
                                            selected={article.type}
                                            onChange={handleChange}
                                            canAdd={user_is_kb_admin}
                                            canEdit={user_is_kb_admin}
                                        />
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <ArticleVisibilityInput
                                            onChange={handleChange}
                                            visibilityValue={article.visibility}
                                            publicKBSValue={article?.public_knowledgebases}
                                        />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="tags">
                                            <Form.Label>Tags</Form.Label>
                                            <TagsInput tagsList={article?.tags} onChange={handleTagsChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="Divisions/Depts">
                                            <DivisionMultiSelectInput
                                                label={'Assign to Divisions/Depts'}
                                                value={article.division}
                                                handleInputChange={handleReactSelectMultiInputChange}
                                                name={'division'}
                                                // disabled={article.individuals?.length > 0}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="Individuals">
                                            <UsersMultiSelectInput
                                                label={'Assign to Individuals'}
                                                value={article.individuals}
                                                handleInputChange={handleReactSelectMultiInputChange}
                                                name={'individuals'}

                                                // disabled={article.division?.length > 0}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="Positions">
                                            <PositionMultiSelectInput
                                                label={'Assign to Positions'}
                                                value={article.assigned_positions}
                                                canAdd={false}
                                                handleInputChange={handleReactSelectMultiInputChange}
                                                name={'assigned_positions'}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="Responsibilities">
                                            <ResponsibilitiesInput
                                                label={'Assign to Responsibilities'}
                                                value={article.assigned_responsibilities}
                                                canAdd={false}
                                                handleInputChange={handleReactSelectMultiInputChange}
                                                name={'assigned_responsibilities'}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="assigntoEveryone">
                                            <Form.Check
                                                type="checkbox"
                                                label="Assign to Everyone"
                                                name={'is_assigned_to_everyone'}
                                                checked={article.is_assigned_to_everyone}
                                                onChange={(e) => {
                                                    handleChange(e, true);
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Determines if the article has been assigned to every company user regardless of their
                                                position/division or any other field. New employees will always have this article in their
                                                'to read' list.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Group controlId="articlereassign">
                                            <Form.Label>Reassign frequency (After Every)</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name={'reassign_month_duration'}
                                                value={article.reassign_month_duration}
                                                onChange={handleChange}
                                            >
                                                {articleReassignDurations.map((duration) => {
                                                    return (
                                                        <option key={duration.value} value={duration.value}>
                                                            {duration.label}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                Schedule re-assign of this article. If enabled, users will be reassigned this course after
                                                every period of the specified duration.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                            <Col md={12} className="mt-3">
                                <Form.Group controlId="updateContent">
                                    <RichTextEditor value={article.body} onChange={updateContent}  />
                                </Form.Group>
                                <Form.Group>
                                    <Button
                                        variant="secondary"
                                        onClick={
                                            article.video_url
                                                ? handleRemoveVideo
                                                : () => {
                                                      setOnDeck(!showVideoOptions);
                                                      setShowVideoOptions(!showVideoOptions);
                                                  }
                                        }
                                    >
                                        {article.video_url ? (
                                            <span className="align-items-center">
                                                {' '}
                                                <BiTrash /> Remove Video
                                            </span>
                                        ) : (
                                            <span className="align-items-center">
                                                <BiVideoPlus className="f-20" /> Add Video Recording
                                            </span>
                                        )}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={
                                            article.file_url || article?.file_urls?.length > 0
                                                ? handleRemoveFile
                                                : () => setShowFileOptions(!showFileOptions)
                                        }
                                    >
                                        {article.file_url || article.file_urls?.length > 0 ? (
                                            <span className="align-items-center">
                                                {' '}
                                                <BiTrash /> Remove Files
                                            </span>
                                        ) : (
                                            <span className="align-items-center">
                                                <BiFile className="f-20" /> Add PDF, powerpoint, or other file
                                            </span>
                                        )}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            if (showDecisionChart) {
                                                localStorage.removeItem('articleNodes');
                                                localStorage.removeItem('articleEdge');
                                            }
                                            setShowDecisionChart(!showDecisionChart);
                                            setArticle({ ...article, decision_chart: null });
                                        }}
                                    >
                                        {showDecisionChart ? 'Remove Decision Chart' : 'Add Decision Chart'}
                                    </Button>
                                    {article.video_url && (
                                        <div className="container">
                                            {/*<div className="embed-responsive rounded-video embed-responsive-16by9">*/}
                                            <Player class="w-100 " controls src={article?.video_url}>
                                                <BigPlayButton position="center" />
                                                <ControlBar autoHide={false}>
                                                    <ReplayControl seconds={10} order={2.2} />
                                                    <ForwardControl seconds={10} order={3.2} />
                                                    <PlaybackRateMenuButton rates={[2, 1.5, 1.2, 1, 0.7, 0.5]} />
                                                </ControlBar>
                                            </Player>
                                            {/*</div>*/}
                                        </div>
                                    )}
                                    {article.file_url && <RenderFiles urls={[article.file_url]} />}
                                    {article.file_urls?.length > 0 && <RenderFiles urls={article.file_urls} />}
                                    <div className={showVideoOptions ? '' : 'd-none'}>
                                        <KBArticleMediaRecorder setVideo={handleSetArticleVideo} allTypes={false} setOnDeck={setOnDeck} />
                                    </div>
                                    <div className={showFileOptions ? '' : 'd-none'}>
                                        <KBArticleMediaRecorder 
                                            setVideo={handleSetArticleFile} 
                                            allTypes={true} 
                                            setOnDeck={setOnDeck}
                                            initialFiles={article.file_urls || []} 
                                        />
                                    </div>
                                    {showDecisionChart && (
                                        <div className="my-3" style={{ height: '800px', width: '100%', overflow: 'hidden' }}>
                                            <ArticleFlow
                                                id={articleId}
                                                setData={setArticle}
                                                editmode={true}
                                                decision_chart={article.decision_chart}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                                <KBChallengeBuilderSelect
                                    selected={article.acknowledgement_type}
                                    question={article.acknowledgement_question}
                                    answers={article.article_answers}
                                    onChange={handleKBChallengeChange}
                                />

                                <Form.Group controlId="courseBody" className="float-right">
                                    <div className="d-flex">
                                        {editArticle && (
                                            <Button className="btn-danger" onClick={deleteArticle}>
                                                Delete
                                            </Button>
                                        )}
                                        {formActionButtons}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )}
            </Card.Body>
        </>
    );
}
