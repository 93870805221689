import { useState } from 'react';
import {Modal} from "react-bootstrap";
import RenderFiles from './DisplayingFiles/RenderFiles';


export default function ViewFileModal({fileUrl, icon = <span><i className='fas fa-eye'/> View File</span>, className = 'btn btn-default'}) {
  const [showModal, setShowModal] = useState(false);

  const viewFile = (e) => {
      e.preventDefault();
    setShowModal(true);
    // If the file URL needs to be updated, do so here with setFileUrl('your-file-url');
  }
  console.log({showModal})

  const closeFileViewer = () => {
    setShowModal(false);
  }
  console.log({fileUrl})

  return (
    <div>
      <button
        className={className}
        onClick={viewFile}
      >
        {icon}
      </button>

      {showModal && (
        <Modal size='lg' show={showModal} backdrop="static" onHide={closeFileViewer}>
            <Modal.Header closeButton/>
          <Modal.Body >
          {/* <iframe src={fileUrl} style={{ height: 1000, width: '100%' }} title="File Viewer"/> */}
          {/* todo: there is an issue with gettign this file to rent */}
          {fileUrl && <RenderFiles urls={[fileUrl]} />}
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};