import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Cascader from 'rc-cascader';
import 'rc-cascader/assets/index.less';
import { Knowledge } from '../../services';
import CreateCategoryModal from './Categories/Modals/CreateCategoryModal';
import UpdateCategoryModal from './Categories/Modals/UpdateCategoryModal';

export function ArticleCategorySelect({ article, setArticle, errors }) {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [rawCategories, setRawCategories] = useState([]);
    const [cascaderValue, setCascaderValue] = useState([]);

    const findCategoryById = (id) => {
        for (const item of categories) {
            if (item.id === id) {
                return item;
            }
            if (item.items) {
                const found = findCategoryById(item.items, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    // Find the path to a category by its ID
    const findCategoryPath = (cats, targetId) => {
        for (let i = 0; i < cats.length; i++) {
            const cat = cats[i];
            
            if (cat.value === targetId) {
                return [cat.value];
            }
            
            if (cat.children?.length) {
                const childPath = findCategoryPath(cat.children, targetId);
                if (childPath) {
                    return [cat.value, ...childPath];
                }
            }
        }
        return null;
    };

    const getArticleCategories = async () => {
        setLoading(true);
        try {
            const data = await Knowledge.getArticleCategories({ paginate: false, simple: true });
            data?.sort((a, b) => a.category.localeCompare(b.category));
            const formattedCategories = formatCategories(data);
            setCategories(formattedCategories);
            setRawCategories(data);

            // Set initial selection if article has a category
            if (article?.article_category) {
                const initialLabel = findCategoryLabel(data, article.article_category);
                console.log('initialLabel', initialLabel);
                if (initialLabel) {
                    setSelectedCategory({ id: article.article_category, category: initialLabel, parent: '' });
                    
                    // Find and set the path for the Cascader
                    const path = findCategoryPath(formattedCategories, article.article_category);
                    if (path) {
                        setCascaderValue(path);
                    }
                }
            } else {
                setSelectedCategory({id: formattedCategories[0]._original.id, category: formattedCategories[0]._original.category, parent: formattedCategories[0]._original.parent || ''});
                const path = findCategoryPath(formattedCategories, formattedCategories[0].value);
                if (path) {
                    setCascaderValue(path);
                }
                
                // Update the article object in parent component with default category
                setArticle((prevArticle) => ({
                    ...prevArticle,
                    article_category: formattedCategories[0]._original.id
                }));
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
        setLoading(false);
    };

    const findCategoryLabel = (cats, targetId) => {
        for (const cat of cats) {
            if (cat.id === targetId) return cat.category;
            if (cat.subcategories?.length) {
                const found = findCategoryLabel(cat.subcategories, targetId);
                if (found) return found;
            }
        }
        return null;
    };

    const formatCategories = (cats) => {
        return cats.map((cat) => ({
            label: cat.category,
            value: cat.id,
            children: cat.subcategories?.length ? 
                formatCategories([...cat.subcategories].sort((a, b) => a.category.localeCompare(b.category))) : 
                undefined,
            // Store original data for later use
            _original: {
                id: cat.id,
                category: cat.category,
                parent: cat.parent
            }
        }));
    };

    const handleSelect = (values, selectedOptions) => {
        if (!values || !selectedOptions || selectedOptions.length === 0) {
            setCascaderValue([]);
            setSelectedCategory(null);
            setArticle(prevArticle => ({ ...prevArticle, article_category: null }));
            return;
        }

        // Get the last selected option (the leaf node)
        const selectedOption = selectedOptions[selectedOptions.length - 1];
        
        if (!selectedOption || !selectedOption._original) return;

        const selectedCat = selectedOption._original;
        
        // Update all states
        setCascaderValue(values);
        setSelectedCategory(selectedCat);
        setArticle(prevArticle => ({
            ...prevArticle,
            article_category: selectedCat.id
        }));

        console.log('Selected:', {
            values,
            selectedCat,
            cascaderValue: values
        });
    };

    useEffect(() => {
        getArticleCategories();
    }, []);

    const onCategoryUpdateOrCreateSuccess = async (data) => {
        try {
            // First get fresh categories
            const freshCategories = await Knowledge.getArticleCategories({ paginate: false, simple: true });
            freshCategories?.sort((a, b) => a.category.localeCompare(b.category));
            
            // Format and set categories
            const formattedCategories = formatCategories(freshCategories);
            setCategories(formattedCategories);
            setRawCategories(freshCategories);

            // Update article category
            setArticle(prevArticle => ({ ...prevArticle, article_category: data.id }));
            
            // Find and set the path for the new/updated category
            const path = findCategoryPath(formattedCategories, data.id);
            console.log('New category path:', path); // Debug log
            
            if (path) {
                setCascaderValue(path);
                setSelectedCategory({
                    id: data.id,
                    category: data.category,
                    parent: data.parent || ''
                });
            }
        } catch (error) {
            console.error('Error updating categories:', error);
        }
    };

    const onDeleteCategorySuccess = async (categoryId) => {
        // Called when a category is successfully deleted

        //  Reset the selected category
        handleSelect(null, []);

        // Remove the deleted category from the categories list
        setCategories(categories.filter((category) => category.id !== categoryId));
        setRawCategories(rawCategories.filter((category) => category.id !== categoryId));
    };

    // For displaying the selected value in the input
    const displayRender = (labels, selectedOptions) => {
        return labels.join(' > ');
    };

    // Filter function for search
    const filter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };

    // Custom icons for the cascader using Feather icon classes
    const expandIcon = () => <i className="feather icon-chevron-right"></i>;
    const loadingIcon = () => <i className="feather icon-loader"></i>;


    return (
        <Form.Group>
            <Form.Label>Category</Form.Label>
            <div className="d-flex">
                <Form.Group controlId="articleCategory" className="w-100">
                        <Cascader
                            options={categories}
                            onChange={handleSelect}
                            placeholder={loading ? "Loading..." : "Select Category"}
                            expandTrigger="hover"
                            displayRender={displayRender}
                            showSearch={{ filter }}
                            changeOnSelect={true}
                            expandIcon={expandIcon}
                            loadingIcon={loadingIcon}
                            className={errors?.article_category ? 'cascader-error' : ''}
                            value={cascaderValue}
                            notFoundContent="No categories found"
                            loadData={() => {}}
                        />
                    {errors?.article_category && (
                        <div className="text-danger">Please select a category</div>
                    )}
                </Form.Group>

                <CreateCategoryModal onCreateSuccess={onCategoryUpdateOrCreateSuccess} categories={rawCategories} />

                {selectedCategory?.id && (
                    <UpdateCategoryModal
                        category={selectedCategory}
                        onUpdateSuccess={onCategoryUpdateOrCreateSuccess}
                        showDeleteButton={true}
                        onDeleteSuccess={onDeleteCategorySuccess}
                        categories={rawCategories}
                    />
                )}
            </div>
        </Form.Group>
    );
}
