import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import CustomTooltip from '../../../CustomTooltip';
import CategoryForm from '../../Forms/CategoryForm';

export default function CreateCategoryModal({ onCreateSuccess, categories }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log('onCreateSuccess', onCreateSuccess);
    console.log('onCreateSuccess is function', typeof onCreateSuccess === 'function');

    const handleCreateSuccess = async (data) => {
        console.log('is called in modal create', data);
        typeof onCreateSuccess === 'function' && (await onCreateSuccess(data));
        handleClose();
    };

    return (
        <>
            <CustomTooltip title="Create an Article Category">
                <Button
                    variant={'primary'}
                    className="btn btn-sm btn-icon btn-primary mx-1"
                    onClick={handleShow}
                    style={{ height: '42.8px' }}
                >
                    <FaPlus />
                </Button>
            </CustomTooltip>

            <Modal backdrop="static" enforceFocus={false} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategoryForm onSubmitSuccess={handleCreateSuccess} loadedCategories={categories} />
                </Modal.Body>
            </Modal>
        </>
    );
}
