export const BOX_LAYOUT = 'BOX_LAYOUT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const RESET = 'RESET';
export const RTL_LAYOUT = 'RTL_LAYOUT';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const SET_USER = 'SET_USER';
export const SET_REQUESTED_URL = 'SET_REQUESTED_URL';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_POSITIONS = 'SET_POSITIONS';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

export const CHANGE_LANG = 'CHANGE_LANG';

export const GET_PROFILE = 'GET_PROFILE';
export const EDIT_PUBLIC_PROFILE = 'EDIT_PUBLIC_PROFILE';

export const SET_COMPANY = 'SET_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';

export const GET_POSITIONS = 'GET_POSITIONS';

export const GET_DIVISIONS = 'GET_DIVISIONS';

export const GET_MANAGERS = 'GET_MANAGERS';

export const GET_USER_TYPES = 'GET_USER_TYPES';

export const UPDATE_ORG_CHART = 'UPDATE_ORG_CHART';
export const ORG_CHART_UPDATED = 'ORG_CHART_UPDATED';

export const UPDATE_ORG_CHART_DIV = 'UPDATE_ORG_CHART_DIV';
export const UPDATE_ORG_CHART_POSITION = 'UPDATE_ORG_CHART_POSITION';
export const SET_ORG_FLOW_EDIT = 'SET_ORG_FLOW_EDIT';
export const SET_ORG_FLOW_STYLE = 'SET_ORG_FLOW_STYLE';
export const SET_HORIZONTAL_ORG_DATA = 'SET_HORIZONTAL_ORG_DATA';

export const SET_ORG_DATA = 'SET_ORG_DATA';
export const SET_FILE_TYPES = 'SET_FILE_TYPES';

//this is how you start the confetti
export const SET_CONFETTI = 'SET_CONFETTI';

export const GET_MY_REQUESTS = 'GET_MY_REQUESTS';

export const GET_REQUEST_DETAILS = 'GET_REQUEST_DETAILS';

export const LOADING_REQUEST_DETAILS = 'LOADING_REQUEST_DETAILS';
export const REQUEST_DETAILS_ERROR = 'REQUEST_DETAILS_ERROR';

export const GET_REQUESTS_FOR_APPROVAL = 'GET_REQUESTS_FOR_APPROVAL';

export const GET_REQUESTS = 'GET_REQUESTS';

export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';

export const GET_MY_PURCHASE_REQUESTS = 'GET_MY_PURCHASE_REQUESTS';

export const GET_ALL_PURCHASE_REQUESTS = 'GET_ALL_PURCHASE_REQUESTS';

export const GET_PURCHASES_TO_APPROVE = 'GET_PURCHASES_TO_APPROVE';

export const GET_PURCHASE_DETAILS = 'GET_PURCHASE_DETAILS';

export const GET_PURCHASE_TYPE = 'GET_PURCHASE_TYPE';

export const GET_PURCHASE_METHOD = 'GET_PURCHASE_METHOD';

export const GET_PURCHASE_SET_A_SIDE = 'GET_PURCHASE_SET_A_SIDE';

export const GET_TAGS = 'GET_TAGS';

export const GET_STATS_LIST = 'GET_STATS_LIST';
export const GET_STATS_LIST2 = 'GET_STATS_LIST2';
export const GET_STATS_ARCHIVE = 'GET_STATS_ARCHIVE';
export const UPDATE_STATS_LIST = 'UPDATE_STATS_LIST';
export const DELETE_STAT = 'DELETE_STAT';
export const ADD_STAT = 'ADD_STAT';

// knowledge base actions
export const SET_COURSE_SECTIONS = 'SET_COURSE_SECTIONS';
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE';
export const ADD_COURSE_SECTION_ARTICLE = 'ADD_COURSE_SECTION_ARTICLE';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const UPDATE_SECTION_TITLE = 'UPDATE_SECTION_TITLE';
export const UPDATE_COURSE_SECTION_ARTICLES = 'UPDATE_COURSE_SECTION_ARTICLES';
export const SET_COURSE_CATEGORIES = 'SET_COURSE_CATEGORIES';
export const SET_COURSE_DIVISIONS = 'SET_COURSE_DIVISIONS';
export const SET_COURSE_INDIVIDUALS = 'SET_COURSE_INDIVIDUALS';
export const SET_COURSE_POSITIONS = 'SET_COURSE_POSITIONS';
export const SET_COURSE_RESPONSIBILITIES = 'SET_COURSE_RESPONSIBILITIES';
export const SET_COURSE_FORM_ERRORS = 'SET_COURSE_FORM_ERRORS';
export const CLEAR_KB_STATE = 'CLEAR_KB_STATE';
export const SET_COURSE_EDIT_DATA = 'SET_COURSE_EDIT_DATA';
export const SET_COURSE_DETAIL_ARTICLES = 'SET_COURSE_DETAIL_ARTICLES';
export const SET_COURSE_DETAIL_ITEMS = 'SET_COURSE_DETAIL_ITEMS';
export const SET_COURSE_SECTION_ITEM_BEING_READ_ID = 'SET_COURSE_SECTION_ITEM_BEING_READ_ID';
export const SET_HAS_COMPLETED_ALL_COURSE_ITEMS = 'SET_HAS_COMPLETED_ALL_COURSE_ITEMS';
export const SET_COURSE_DETAIL = 'SET_COURSE_DETAIL';
export const SET_COURSE_PROGRESS = 'SET_COURSE_PROGRESS';
export const SET_QUIZZES = 'SET_QUIZZES';
export const SET_DISABLE_COURSE_ITEM_DRAG = 'SET_DISABLE_COURSE_ITEM_DRAG';
export const TRIGGER_RE_FETCH_COURSE_BUILDER_ARTICLES = 'TRIGGER_RE_FETCH_COURSE_BUILDER_ARTICLES';

export const TRIGGER_RE_FETCH_QUESTIONS = 'TRIGGER_RE_FETCH_QUESTIONS';
export const TRIGGER_RE_FETCH_ARTICLE_DETAIL_ACKNOWLEDGEMENTS = 'TRIGGER_RE_FETCH_ARTICLE_DETAIL_ACKNOWLEDGEMENTS';

// Inout Actions
export const SET_CLOCK_TAGS = 'SET_CLOCK_TAGS';
