import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa6';
import knowledgeService from '../../../../services/knowledgeService';
import CustomTooltip from '../../../CustomTooltip';

export default function DeleteCategoryModal({ categoryId, categoryName, onDeleteSuccess }) {
    // const { fetchQuizzes } = useGetQuizzes({ paginate: false });
    const [show, setShow] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async () => {
        setDeleting(true);
        const data = await knowledgeService.deleteArticleCategories(categoryId);
        if (data) {
            typeof onDeleteSuccess === 'function' && onDeleteSuccess(categoryId);
            // await fetchQuizzes();
            handleClose();
        }
        setDeleting(false);
    };

    console.log('categoryId', categoryId);
    console.log('categoryName', categoryName);

    return (
        <>
            <CustomTooltip title="Delete Article Category">
                <Button variant="danger" onClick={handleShow}>
                    <FaTrash />
                </Button>
            </CustomTooltip>

            <Modal backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete this category? <strong>{categoryName}</strong>
                    </p>
                    <p className="text-danger font-weight-bold">This action cannot be undone.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete'}
                    </Button>
                    <Button variant="secondary" onClick={handleClose} disabled={deleting}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
