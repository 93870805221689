// src/components/ErrorBoundary.js
import React from 'react';
import { postToFrontEndErrors } from '../services/actionsService';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            shouldShowError: false 
        };
        
        // Define MAX_REFRESHES as a class property
        this.MAX_REFRESHES = 3;
        // Define time window for errors (30 seconds)
        this.ERROR_TIME_WINDOW_MS = 30 * 1000;
        
        this.managementTips = [
            "Teams often struggle with burnout when focused solely on big milestones. Remember to celebrate small wins along the way to maintain morale and momentum.",
            
            "Misunderstandings can quickly escalate into major workplace conflicts. Clear, consistent communication prevents most issues before they start and builds a foundation of trust.",
            
            "Many managers miss valuable insights because they're too quick to speak. Take time to truly listen to your team's concerns before formulating solutions.",
            
            "Without regular feedback, employees can feel uncertain about their performance and development path. Schedule consistent check-ins to provide specific guidance and recognize progress.",
            
            "Disengaged teams deliver mediocre results regardless of talent or resources. A motivated team with clear purpose will outperform expectations even with limited resources.",
            
            "Team members quickly notice discrepancies between what leaders say and what they do. Lead by example in work ethic, communication style, and values to establish credibility.",
            
            "Employee turnover often stems from feeling undervalued despite hard work. Recognition for specific contributions goes a long way in building morale and loyalty.",
            
            "Assigning tasks without considering individual strengths can lead to inefficiency and frustration. Delegate responsibilities based on team members' natural abilities to maximize both satisfaction and results.",
            
            "Innovation stalls when team members fear criticism for suggesting new ideas. Create a culture of psychological safety where people feel comfortable taking risks without fear of humiliation.",
            
            "Unclear expectations are the root of most performance issues and disappointments. Set specific, measurable objectives with your team to provide focus and enable success.",
            
            "When information is withheld, rumors and mistrust quickly fill the void. Practice transparency in decision-making and organizational changes to build lasting trust within your team.",
            
            "Leaders who neglect their own development eventually reach the limits of their effectiveness. Make time for your professional growth so you can continue to lead your team to new heights.",
            
            "Many workplace stresses stem from poor prioritization and time management. Implement structured frameworks for managing competing priorities to boost team productivity and reduce overwhelm.",
            
            "Chronic overwork leads to diminished productivity, poor decisions, and eventually burnout. Encourage genuine work-life balance by respecting boundaries and focusing on outcomes rather than hours worked.",
            
            "Teams often avoid giving feedback upward, missing opportunities for leadership improvement. Create channels for receiving honest input about your management style and demonstrate that you value this feedback.",
            
            "Micromanagement crushes initiative and creates bottlenecks around the manager. Empower your team to make appropriate decisions by providing clear guidelines rather than step-by-step instructions.",
            
            "Organizations often plateau when leadership remains concentrated at the top. Great leaders focus on developing more leaders throughout the organization, not just accumulating followers.",
            
            "Annual reviews provide too little feedback too late to meaningfully impact performance. Regular one-on-ones build stronger working relationships and allow for timely course corrections throughout the year.",
            
            "Unaddressed conflicts drain team energy and create toxic workplace dynamics. Develop strong conflict resolution skills to transform disagreements into opportunities for understanding and innovation.",
            
            "Companies that rest on past success risk becoming irrelevant as markets evolve. Foster a culture of continuous learning where experimentation is encouraged and failures are viewed as valuable lessons."
        ];
    }

    static getDerivedStateFromError(error) {
        // Only update hasError, don't show anything yet
        return { hasError: true, shouldShowError: false };
    }

    componentDidMount() {
        // Record the time when the page successfully loads
        localStorage.setItem('lastPageLoadTime', Date.now().toString());
        // Clear the error state when component mounts successfully
        localStorage.removeItem('errorState');
    }

    componentDidCatch(error, errorInfo) {
        console.error('ErrorBoundary caught an error', error, errorInfo);
        
        // Only handle truly fatal rendering errors
        const isFatalRenderError = error.message && (
            /Cannot read properties of (null|undefined)/.test(error.message) ||
            /is not a function/.test(error.message) ||
            /Cannot destructure property/.test(error.message) ||
            /Invalid attempt to (destructure|spread|iterate)/.test(error.message) ||
            /is not defined/.test(error.message) ||
            /Loading chunk \d+ failed/.test(error.message)
        );
        
        // Log all errors
        postToFrontEndErrors({
            error_name: error.name,
            message: error.message,
            error: errorInfo,
            is_fatal: isFatalRenderError
        });
        
        // For non-fatal errors, just log but don't interrupt rendering
        if (!isFatalRenderError) {
            // Reset the error state so normal rendering continues
            this.setState({ hasError: false });
            return;
        }
        
        // For fatal errors, check refresh attempts
        const errorState = JSON.parse(localStorage.getItem('errorState') || '{"count": 0, "timestamp": 0}');
        const currentTime = Date.now();
        
        // Reset count if the last error was more than 30 seconds ago
        if (currentTime - errorState.timestamp > this.ERROR_TIME_WINDOW_MS) {
            errorState.count = 0;
        }
        
        if (errorState.count >= this.MAX_REFRESHES) {
            // We've already tried refreshing, now show the fallback UI
            this.setState({ shouldShowError: true });
        } else {
            // Update the error state BEFORE attempting refresh
            const newCount = errorState.count + 1;
            localStorage.setItem('errorState', JSON.stringify({
                count: newCount,
                timestamp: currentTime
            }));
            
            console.log(`Auto-refreshing (attempt ${newCount} of ${this.MAX_REFRESHES})...`);
            
            // Immediate reload
            window.location.reload();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Remove this timeout - it causes the error UI to show prematurely
        // We only want to show the error UI after MAX_REFRESHES
    }

    render() {
        // Get current refresh count in the render method to ensure it's up-to-date
        const errorState = JSON.parse(localStorage.getItem('errorState') || '{"count": 0, "timestamp": 0}');
        
        // Only show error UI if all conditions are met
        if (this.state.hasError && errorState.count >= this.MAX_REFRESHES) {
            const randomTip = this.managementTips[Math.floor(Math.random() * this.managementTips.length)];
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h1>Something went wrong!</h1>
                    <p>Please reload the page or contact support.</p>
                    <h3 className="mt-3 text-muted small">
                        Management Tip: {randomTip}
                    </h3>
                </div>
            );
        }
        
        // If hasError but we haven't reached max refreshes, return null
        // This prevents any content from showing during refresh
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
