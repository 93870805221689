import React, { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ResponsibilityForm from './ResponsibilityForm';

const AddResponsibilityModal = ({ updateResponsibilities, selectResponsibility }) => {
    const [modalShow, setModalShow] = useState(false);

    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    return (
        <>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Create New Responsibility</Tooltip>}>
                <button className="btn btn-sm btn-icon mx-0" type="button" style={{ height: 42.8 }} onClick={handleShowModal}>
                    {' '}
                    <i className="feather icon-plus text-dark fa-lg mx-0"> </i>
                </button>
            </OverlayTrigger>

            <Modal backdrop="static" show={modalShow} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Responsibility</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ResponsibilityForm
                        updateResponsibilities={updateResponsibilities}
                        selectResponsibility={selectResponsibility}
                        closeModalFunc={handleCloseModal}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddResponsibilityModal;
