import axios from 'axios';
import { toast } from 'react-toastify';
import { API_SERVER } from '../config/constant';
import { store } from '../store';
import { LOGOUT } from '../store/actions';
import http, { serializeQuery, showAxiosErrorNotification } from './httpService';

const logoutUser = async () => {
    // Logout should always return true irregardless of the response status
    await http.post(API_SERVER + 'account/api/logout/', {}, http.tokenConfig());
    store.dispatch({ type: LOGOUT });
    return true;
};

const getDivisionUsers = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}division/api/division_users/${id}/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const getDivisions = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}division/api/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const createDivision = async (formData) => {
    console.log('create division formData >>', formData);
    try {
        const { data } = await http.post(`${API_SERVER}division/api/`, formData, http.tokenConfig());
        return data;
    } catch (err) {
        toast.error('An error occurred, please try again.' + JSON.stringify(err.response?.data));
        console.log(err);
        console.log(err.response);
        return false;
    }
};

export const updateDivision = async (id, formData) => {
    try {
        const { data } = await http.patch(`${API_SERVER}division/api/${id}/`, formData, http.tokenConfig());
        return data;
    } catch (err) {
        toast.error('An error occurred, please try again....' + JSON.stringify(err.response?.data));
        console.log(err);
        return false;
    }
};

export const updateStaffTimeOffDetails = async (id, formData) => {
    try {
        const { data } = await http.patch(`${API_SERVER}request/api/staff-time-offs-detail/${id}/`, formData, http.tokenConfig());
        return data;
    } catch (err) {
        toast.error('An error occurred, please try again....' + JSON.stringify(err.response?.data));
        console.log(err.response);
        return false;
    }
};

export const createStaff = async (formData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/staffs/create_company_staff/`, formData, http.tokenConfig());
        toast.success('Staff added successfully!');
        return data;
    } catch (err) {
        toast.error('Error creating staff ' + JSON.stringify(err.response.data));
        console.log(err);
        return false;
    }
};

export const sendActivationEmail = async (postData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/send-activation-email/`, postData, http.tokenConfig());
        toast.success(data.message);
        return data;
    } catch (error) {
        showAxiosErrorNotification(error?.response);
    }
};

export const bulkCreateEmployeesFromFile = async (data) => {
    try {
        const { data: responseData } = await http.post(`${API_SERVER}account/api/bulk-create-staff/`, data, http.tokenConfig());
        toast.success(responseData.message);
        return responseData;
    } catch (error) {
        showAxiosErrorNotification(error?.response);
    }
};

export const updateStaff = async (id, formData) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/staffs/${id}/update_staff/`, formData, http.tokenConfig());
        toast.success('User info updated successfully!');
        return data;
    } catch (err) {
        toast.error('Error updating staff ' + JSON.stringify(err.response.data));
        console.log(err);
        return false;
    }
};

export const updateStaffPrivateInfo = async (id, body) => {
    try {
        const { data } = await http.put(`${API_SERVER}account/api/staffs/${id}/private_info_update/`, body, http.tokenConfig());
        toast.success('User info updated successfully!');
        return data;
    } catch (err) {
        toast.error('Error updating staff ' + JSON.stringify(err.response.data));
        console.log(err);
        return false;
    }
};

export const editProfileImage = async (id, body) => {
    try {
        const { data } = await http.put(`${API_SERVER}account/api/staffs/${id}/upload_photo/`, body, http.multiPartTokenConfig());
        toast.success('Profile image updated successfully!');
        return data;
    } catch (err) {
        toast.error('Error updating staff ' + JSON.stringify(err.response.data));
        console.log(err);
        return false;
    }
};

export const getResponsibilities = async (pageSize) => {
    try {
        const pageSizeParam = pageSize ? `?page_size=${pageSize}` : '';
        const { data } = await http.get(`${API_SERVER}account/api/responsibilities/${pageSizeParam}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

const getPositionUsers = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/position_users/${id}/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

const getSingleUserResponsibilities = async (id, page) => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/employee-responsibilities/?id=${id}&page=${page}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getCompanyUsers = async (query) => {
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}account/api/staffs/company_users/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getAllUsers = async (all_users = false) => {
    return await getCompanyUsers({ all_users: all_users });
};

export const getUser = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/staffs/${id}/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getUserTypes = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/user-type/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getAllUsersSimple = async (queryParamsDict) => {
    const query = queryParamsDict ? serializeQuery(queryParamsDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}account/api/company-users-simple/?${query}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getProfile = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/staffs/${id}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

const getStaffTimeOffTracker = async (userId, queryDict) => {
    const queryParams = queryDict ? serializeQuery(queryDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}request/api/user-requests/${userId}/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};
export const getPositions = async (queryParamsDict) => {
    const query = queryParamsDict ? serializeQuery(queryParamsDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}account/api/positions/?${query}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('Error fetching positions >>>', err);
        return false;
    }
};

export const getPosition = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/positions/${id}/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getPositionManagers = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/positions/${id}/employees-with-managers/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const getStaffRequestsSummary = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}request/api/staff-requests-summary/${id}/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('Error staff requests summary >>>', err);
        return false;
    }
};

export const getDivisionPositionHierarchy = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/division-position-hierarchy/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('Error fetching positions >>>', err);
        return false;
    }
};

export const getEmployeePositionHierarchy = async (search) => {
    const query = search ? `?search=${encodeURIComponent(search)}` : '';
    try {
        const { data } = await http.get(`${API_SERVER}account/api/employee-position-hierarchy/${query}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('Error fetching positions >>>', err);
        return false;
    }
};

export const createPosition = async (formData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/positions/`, formData, http.tokenConfig());
        toast.success('position created successfully!');
        return data;
    } catch (err) {
        console.log('Error creating positions >>>', err.response);
        toast.error('Error creating position ' + JSON.stringify(err?.response?.data));
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

export const updatePosition = async (formData, id) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/positions/${id}/`, formData, http.tokenConfig());
        toast.success(`position: ${data.name} updated successfully!`);
        return data;
    } catch (err) {
        toast.error('Error updating position ' + JSON.stringify(err?.response?.data));
        console.log('Error updating positions>>>', err.response);
        // if (err.response) {
        //     showAxiosErrorNotification(err.response);
        // }
        // return false;
    }
};

export const deletePosition = async (id) => {
    try {
        const { data } = await http.delete(`${API_SERVER}account/api/positions/${id}/`, http.tokenConfig());
        toast.success('Position deleted successfully!');
        console.log('deletePosition data', { data });
        return { id: id, deleted: true };
    } catch (err) {
        console.log('Error deleting positions >>>', err.response);
        toast.error('Error deleting positions!' + JSON.stringify(err?.response?.data));
        // if (err.response) {
        //     showAxiosErrorNotification(err.response);
        // }
        return false;
    }
};

export const deleteStaff = async (id) => {
    try {
        await http.delete(`${API_SERVER}account/api/staffs/${id}/delete_staff/`, http.tokenConfig());
        toast.success('Staff deleted successfully!');
        return 'success';
    } catch (err) {
        console.log('Error deleting Staff >>>', err.response);
        toast.error('Error deleting staff!' + JSON.stringify(err.response?.data));

        return false;
    }
};

export const uploadEmployeeFile = async (staffId, body) => {
    let formData = new FormData();
    for (var key in body) {
        formData.append(key, body[key] === null ? '' : body[key]);
    }
    try {
        const { data } = await http.post(
            `${API_SERVER}account/api/staffs/${staffId}/upload_employee_file/`,
            formData,
            http.multiPartTokenConfig()
        );
        toast.success('File uploaded successfully!');
        return data;
    } catch (error) {
        showAxiosErrorNotification(error?.response);
    }
};

export const deleteEmployeeFile = async (userId, fileId) => {
    try {
        await http.delete(`${API_SERVER}account/api/employee_files/${fileId}/staff/${userId}/`, http.tokenConfig());
        toast.success('File deleted successfully!');
        return 'success';
    } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                toast.error('file not found');
            } else {
                showAxiosErrorNotification(error.response);
            }
        }

        return false;
    }
};

const getEmployeeResponsibilities = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/employee-responsibilities/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('error is >>>', err);
        return false;
    }
};

const createResponsibility = async (formData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/create-employee-responsibilities/`, formData, http.tokenConfig());
        toast.success('responsibility created successfully!');
        return data;
    } catch (err) {
        console.log('Error creating responsibilities >>>>', err);
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
    }
};

const updateResponsibility = async (formData, id) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/employee-responsibilities/${id}/`, formData, http.tokenConfig());
        toast.success('Responsibility updated successfully!');
        return data;
    } catch (err) {
        console.log('Error updating resposibility >>>', err);
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

export const deleteResponsibility = async (id) => {
    try {
        await http.delete(`${API_SERVER}account/api/employee-responsibilities/${id}/`, http.tokenConfig());
        toast.success('Responsibility deleted successfully!');
        return 'success';
    } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                toast.error('Responsibility not found');
            } else {
                showAxiosErrorNotification(error.response);
            }
        }
        return false;
    }
};
const activateDeactivateUser = async (formData, id) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/staffs/${id}/update_staff/`, formData, http.tokenConfig());
        return data;
    } catch (err) {
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const updateStaffInfo = async (id, formData) => {
    try {
        const { data } = axios.post(`${API_SERVER}account/api/update_staff_info/${id}/`, formData, http.tokenConfig());
        return data;
    } catch (err) {
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const resetPassword = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}account/api/password-reset-request/`, data, http.tokenConfig());
        return res.data;
    } catch (err) {
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const resetPasswordConfirm = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}account/api/reset-password-confirm/`, data, http.tokenConfig());
        toast.success('Password reset success!');
        return res.data;
    } catch (err) {
        console.log('error ::::', err?.response);
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const setPassword = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}account/api/set-password/`, data, http.tokenConfig());
        toast.success('Password set successfully!');
        return res.data;
    } catch (err) {
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const getAllHolidays = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/holidays/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('error is >>>', err);
        return false;
    }
};

const createHoliday = async (formData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/holidays/`, formData, http.tokenConfig());
        toast.success('Holiday created successfully!');
        return data;
    } catch (err) {
        console.log('error is >>>', err);
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const updateHoliday = async (formData, id) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/holidays/${id}/`, formData, http.tokenConfig());
        toast.success('Holiday updated successfully!');
        return data;
    } catch (err) {
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const deleteHoliday = async (id) => {
    try {
        await http.delete(`${API_SERVER}account/api/holidays/${id}/`, http.tokenConfig());
        toast.success('Holiday deleted successfully!');
        return 'success';
    } catch (err) {
        if (err.response) {
            showAxiosErrorNotification(err.response);
        }
        return false;
    }
};

const getCalendarEvents = async (queryParamsDict) => {
    const query = queryParamsDict ? serializeQuery(queryParamsDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}account/api/time-off-calendar/?${query}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('error is >>>', err);
        return false;
    }
};

const getOutboundEmails = async (queryParamsDict) => {
    const query = queryParamsDict ? serializeQuery(queryParamsDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}account/api/outbound-emails/?${query}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('error is >>>', err);
        return false;
    }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logoutUser,
    getDivisionUsers,
    getDivisions,
    createDivision,
    updateDivision,
    getPositionUsers,
    getPosition,
    getPositions,
    getPositionManagers,
    getDivisionPositionHierarchy,
    getEmployeePositionHierarchy,
    getCompanyUsers,
    getAllUsers,
    getProfile,
    getUser,
    updateResponsibility,
    getEmployeeResponsibilities,
    createResponsibility,
    deleteResponsibility,
    updatePosition,
    deletePosition,
    activateDeactivateUser,
    getSingleUserResponsibilities,
    deleteStaff,
    getStaffTimeOffTracker,
    getStaffRequestsSummary,
    updateStaffTimeOffDetails,
    getUserTypes,
    createStaff,
    updateStaff,
    updateStaffPrivateInfo,
    bulkCreateEmployeesFromFile,
    editProfileImage,
    getAllUsersSimple,
    getResponsibilities,
    updateStaffInfo,
    uploadEmployeeFile,
    deleteEmployeeFile,
    sendActivationEmail,
    resetPassword,
    resetPasswordConfirm,
    setPassword,
    getAllHolidays,
    createHoliday,
    updateHoliday,
    deleteHoliday,
    getCalendarEvents,
    getOutboundEmails
};
