//do not remove create stat, removing it from this page for some reason double the build size of the package.
import { settingsPageRoute, timeOffCalendarRoute } from './routes';

const getMenuItems = (user) => {
    const userCompany = user?.company;
    const ActiveApps = userCompany?.active_apps;
    const userRoles = user?.user_roles;
    const isTotalAdmin = user?.user_type?.user_type_slug === 'admin';
    const isAttendanceAdmin = userRoles?.user_is_assignment_admin;
    const isChecklistAdmin = userRoles?.user_is_checklist_admin;
    const isKBAdmin = userRoles?.user_is_kb_admin;
    const isPRAprover = userRoles?.user_is_pr_approver;
    const isPRPayer = userRoles?.user_is_pr_payer;
    const isProfileAdmin = userRoles?.user_is_profile_admin;
    const isRequestAdmin = userRoles?.user_is_request_admin;
    const isStatAdmin = userRoles?.user_is_stat_admin;
    const isSubscribedToBeta = userCompany?.subscribe_to_beta;

    // let companyActiveFeatures
    //
    // // const getCompanyActiveFeatures = async () => {
    // const companyActiveFeatures = await companyService.getCompanyActiveFeatures();
    //
    // // };
    //
    //
    // // const isActive = ActiveApps.includes(feature.id);
    //
    // const inactiveApps =
    //     companyActiveFeatures?.filter(
    //         (feature) => !ActiveApps.some((activeApp) => activeApp.id === feature.id)
    //     );

    // console.log({ActiveApps, userCompany, inactiveApps, companyActiveFeatures})

    // Generate menu items for inactive apps if conditions are met
    // const inactiveAppMenuItems =  inactiveApps
    //     ?
    //
    //             {
    //                 id: 'hidden-features',
    //                 title: 'Hidden Features',
    //                 type: 'group',
    //                 children: inactiveApps?.map((app) => ({
    //                     id: `inactive-${app.app_name}`,
    //                     title: app.app_name,
    //                     type: 'item',
    //                     // url: `/activate_feature?app=${app.app_name}`, // Update with actual URL for activating feature
    //                     icon: 'feather icon-eye-off',
    //                     classes: 'nav-item'
    //                 }))
    //             }
    //
    //
    //     : {};
    // //
    // console.log({inactiveAppMenuItems})

    return process.env.REACT_APP_TRAINING === 'True'
        ? {
              items: [
                  {
                      id: 'navigation',
                      title: 'Navigation',
                      type: 'group',
                      icon: 'icon-navigation',
                      url: '/account/dashboard/',
                      children: [
                          {
                              id: 'dashboard',
                              title: 'Dashboard',
                              type: 'item',
                              url: '/account/dashboard/',
                              icon: 'feather icon-home'
                          },
                          {
                              id: 'layout',
                              title: 'Employees/Users',
                              type: 'collapse',
                              icon: 'feather icon-users',
                              url: '/account/employees',
                              children: [
                                  {
                                      id: 'profile',
                                      title: 'My Profile',
                                      type: 'item',
                                      url: '/account/profile'
                                  },
                                  {
                                      id: 'profile-view',
                                      title: 'Employee Profile',
                                      type: 'hidden',
                                      url: '/account/employee/:id'
                                  },
                                  {
                                      id: 'profile-list',
                                      title: 'Directory',
                                      type: 'item',
                                      url: '/account/employees'
                                  },
                                  {
                                      id: 'profile-create',
                                      title: 'Create New Employee',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/create'
                                  },
                                  {
                                      id: 'positions',
                                      title: 'Positions',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/positions'
                                  },
                                  {
                                      id: 'responsibilities',
                                      title: 'Responsibilities',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/responsibilities'
                                  },
                                  {
                                      id: 'actions',
                                      title: 'System Action History',
                                      type: 'item',
                                      url: '/account/actions/'
                                  }
                              ]
                          },
                          {
                              id: 'Company',
                              title: 'Company',
                              type: 'collapse',
                              icon: 'feather icon-book',
                              // url: '/knowledgebase/articles',
                              children: [
                                  {
                                      id: 'org-flow',
                                      title: 'Org Chart',
                                      type: 'item',
                                      url: '/div/'
                                  }
                              ]
                          },
                          {
                              id: 'layout-kb',
                              title: 'Knowledge Base',
                              type: 'collapse',
                              icon: 'feather icon-book',
                              url: '/knowledgebase/articles',
                              children: [
                                  {
                                      id: 'kb-articles',
                                      title: 'View All Articles',
                                      type: 'item',
                                      url: '/knowledgebase/articles'
                                  },
                                  {
                                      id: 'kb-edit',
                                      title: 'Edit Article',
                                      type: 'hidden',
                                      url: '/knowledgebase/articles/:id/edit'
                                  },
                                  {
                                      id: 'kb-article',
                                      title: 'KB Article',
                                      type: 'hidden',
                                      url: '/knowledgebase/articles/:id'
                                  },
                                  {
                                      id: 'kb-create',
                                      title: 'Create Article',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/articles/create'
                                  }
                              ]
                          },
                          {
                              id: 'courses',
                              title: 'Courses',
                              type: 'collapse',
                              icon: 'feather icon-book',
                              url: '/knowledgebase/articles',
                              children: [
                                  {
                                      id: 'kb-assigned-courses',
                                      title: 'Assigned Courses',
                                      type: 'item',
                                      url: '/knowledgebase/assigned-courses'
                                  },
                                  {
                                      id: 'kb-courses-admin',
                                      title: 'Courses Admin',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/courses'
                                  },
                                  {
                                      id: 'kb-courses-boew',
                                      title: 'Course View',
                                      type: 'hidden',
                                      url: '/knowledgebase/courses/:id'
                                  },
                                  {
                                      id: 'kb-courses-create',
                                      title: 'Create Course',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/courses/create'
                                  }
                              ]
                          },
                          {
                              id: 'progress',
                              title: 'All Completions',
                              icon: 'feather icon-book',
                              type: isKBAdmin ? 'item' : 'hidden',
                              url: '/knowledgebase/acknowledgement-timeline'
                          },
                          {
                              id: 'kb-public',
                              title: 'View Public KB ',
                              icon: 'feather icon-book',
                              type: userCompany?.company_slug ? 'item' : 'hidden',
                              url: `/articles/public/${userCompany?.company_slug}`
                          },
                          {
                              id: 'settings-menu',
                              title: 'Settings',
                              type: 'item',
                              icon: 'feather icon-settings',
                              url: `${settingsPageRoute}`
                          }
                          // need to set up a kb just for trainwell
                          //   {
                          //       id: 'help',
                          //       title: 'Get Help',
                          //       type: 'item',
                          //       icon: 'feather icon-help-circle',
                          //       classes: 'nav-item',
                          //       url: 'https://help.humanagement.io/',
                          //       target: true,
                          //       external: true
                          //   }
                          // {
                          //     id: 'Feedback',
                          //     title: 'Feedback/Roadmap',
                          //     type: 'item',
                          //     icon: 'feather icon-map',
                          //     classes: 'nav-item',
                          //     url: 'https://humanagement.canny.io',
                          //     target: true,
                          //     external: true
                          // }
                      ]
                  }
              ]
          }
        : {
              items: [
                  {
                      id: 'navigation',
                      title: 'Navigation',
                      type: 'group',
                      icon: 'icon-navigation',
                      url: '/account/dashboard/',
                      children: [
                          {
                              id: 'dashboard',
                              title: 'Dashboard',
                              type: 'item',
                              url: '/account/dashboard/',
                              icon: 'feather icon-home'
                          },
                          // {
                          //     id: 'dashboard-1',
                          //     title: 'Dashboard',
                          //     type: 'hidden',
                          //     url: '/',
                          //     icon: 'feather icon-home'
                          // },
                          {
                              id: 'layout',
                              title: 'Employees/Users',
                              type: 'collapse',
                              icon: 'feather icon-users',
                              url: '/account/employees',
                              children: [
                                  {
                                      id: 'profile',
                                      title: 'My Profile',
                                      type: 'item',
                                      url: '/account/profile'
                                  },
                                  {
                                      id: 'profile-view',
                                      title: 'Employee Profile',
                                      type: 'hidden',
                                      url: '/account/employee/:id'
                                  },
                                  {
                                      id: 'profile-list',
                                      title: 'Employee List',
                                      type: 'item',
                                      url: '/account/employees'
                                  },
                                  {
                                      id: 'org-chart',
                                      title: 'Org Chart',
                                      type: 'collapse',
                                      // url: '/account/org-chart/',
                                      children: [
                                          {
                                              id: 'org-flow',
                                              title: 'Org Flow Chart',
                                              type: 'item',
                                              url: '/div/'
                                          },
                                          {
                                              id: 'org-auto-chart',
                                              title: 'Auto Org Chart',
                                              type: 'item',
                                              url: '/account/org-chart/'
                                          }
                                      ]
                                  },

                                  {
                                      id: 'positions',
                                      title: 'Positions',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/positions'
                                  },
                                  {
                                      id: 'responsibilities',
                                      title: 'Responsibilities',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/responsibilities'
                                  },
                                  {
                                      id: 'profile-create',
                                      title: 'Create New Employee',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/create'
                                  },
                                  {
                                      id: 'actions',
                                      title: 'Action List',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/actions/'
                                  },
                                  {
                                      id: 'outbound-emails',
                                      title: 'Emails',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: '/account/outbound-emails/'
                                  }
                              ]
                          },
                          {
                              id: 'layout-attendance',
                              title: 'Attendance',
                              type: ActiveApps?.some((app) => app.app_name === 'attendance') && 'collapse',
                              icon: 'feather icon-clock',
                              url: '/inout/working-hours/',
                              children: [
                                  {
                                      id: 'inoutlist',
                                      title: 'Clock Ins',
                                      type: 'item',
                                      url: '/inout/list/'
                                  },
                                  {
                                      id: 'working-hours',
                                      title: 'Working Hours',
                                      type: 'item',
                                      url: '/inout/working-hours/'
                                  },
                                  {
                                      id: 'time-off-calendar',
                                      title: 'Calendar',
                                      type: 'item',
                                      url: timeOffCalendarRoute
                                  }
                              ]
                          },

                          {
                              id: 'layout-stats',
                              title: 'Statistics',
                              //this is what makes it hide or not
                              type: ActiveApps?.some((app) => app.app_name === 'statistics') && 'collapse',
                              icon: 'feather icon-bar-chart-2',
                              url: '/statistics/single/',
                              children: [
                                  {
                                      id: 'single-stat',
                                      title: 'Stat List',
                                      type: 'item',
                                      url: '/statistics/single/'
                                  },
                                  {
                                      id: 'stat-grid',
                                      title: 'Stat Grid',
                                      type: 'item',
                                      url: '/statistics/multi/'
                                  },

                                  {
                                      id: 'vis-stat',
                                      title: 'Stat Sheet',
                                      type: 'item',
                                      url: '/statistics/statsheet/'
                                  },
                                  {
                                      id: 'int-stat',
                                      title: 'Integrations',
                                      type: 'item',
                                      url: '/statistics/external/'
                                  },
                                  {
                                      id: 'create-stat',
                                      title: 'Create Stat',
                                      type: isStatAdmin ? 'item' : 'hidden',
                                      url: '/statistics/create-stat/'
                                  },
                                  {
                                      id: 'stat-archived',
                                      title: 'Archived',
                                      type: isStatAdmin ? 'item' : 'hidden',
                                      url: '/statistics/archived/'
                                  }
                              ]
                          },
                          {
                              id: 'layout-requests',
                              title: 'Requests',
                              type: ActiveApps?.some((app) => app.app_name === 'request') && 'collapse',
                              icon: 'feather icon-edit-1',
                              url: '/request',
                              children: [
                                  {
                                      id: 'requests',
                                      title: 'View All',
                                      type: 'item',
                                      url: '/request'
                                  },
                                  {
                                      id: 'requests-create',
                                      title: 'Create New Request',
                                      type: 'item',
                                      url: '/request/create'
                                  },
                                  {
                                      id: 'requests-view',
                                      title: 'View Requests',
                                      type: 'hidden',
                                      url: '/request/details/:id'
                                  },
                                  {
                                      id: 'time-off-calendar',
                                      title: 'Calendar',
                                      type: !ActiveApps?.some((app) => app.app_name === 'attendance') ? 'item' : 'hidden',
                                      url: timeOffCalendarRoute
                                  }
                              ]
                          },
                          {
                              id: 'layout-tasks',
                              title: 'Task/Order',
                              type: ActiveApps?.some((app) => app.app_name === 'request') && 'collapse',
                              icon: 'feather icon-clipboard',
                              url: '/tasks/',
                              children: [
                                  {
                                      id: 'tasks',
                                      title: 'View All',
                                      type: 'item',
                                      url: '/tasks/'
                                  },
                                  {
                                      id: 'tasks-view',
                                      title: 'View Task/Order',
                                      type: 'hidden',
                                      url: '/tasks/details/:id'
                                  },
                                  {
                                      id: 'tasks-kanban',
                                      title: 'Progress Board',
                                      type: 'item',
                                      url: '/request/tasks-kanban/'
                                  },
                                  {
                                      id: 'tasks-create',
                                      title: 'Create New Task/Order',
                                      type: 'item',
                                      url: '/tasks/create'
                                  }
                              ]
                          },

                          {
                              id: 'layout-kb',
                              title: 'Knowledge Base',
                              type: ActiveApps?.some((app) => app.app_name === 'knowledge_base') && 'collapse',
                              icon: 'feather icon-book',
                              url: '/knowledgebase/articles',
                              children: [
                                  {
                                      id: 'kb-articles',
                                      title: 'View All Articles',
                                      type: 'item',
                                      url: '/knowledgebase/articles'
                                  },
                                  {
                                      id: 'kb-edit',
                                      title: 'Edit Article',
                                      type: 'hidden',
                                      url: '/knowledgebase/articles/:id/edit'
                                  },
                                  {
                                      id: 'kb-article',
                                      title: 'KB Article',
                                      type: 'hidden',
                                      url: '/knowledgebase/articles/:id'
                                  },
                                  {
                                      id: 'kb-create',
                                      title: 'Create Article',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/articles/create'
                                  },
                                  {
                                      id: 'kb-public',
                                      title: 'View Public Articles',
                                      type: userCompany?.company_slug ? 'item' : 'hidden',
                                      url: `/articles/public/${userCompany?.company_slug}`
                                  },

                                  {
                                      id: 'kb-article-version',
                                      title: 'Article Version',
                                      type: 'hidden',
                                      url: '/knowledgebase/articles/version/:id'
                                  },
                                  {
                                      id: 'kb-article-draft',
                                      title: 'KB Drafts',
                                      type: 'hidden',
                                      url: '/knowledgebase/articles/:id/draft'
                                  },

                                  {
                                      id: 'kb-assigned-courses',
                                      title: 'Assigned Courses',
                                      type: 'item',
                                      url: '/knowledgebase/assigned-courses'
                                  },
                                  {
                                      id: 'kb-courses-admin',
                                      title: 'Courses Admin',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/courses'
                                  },
                                  {
                                      id: 'kb-courses-boew',
                                      title: 'Course View',
                                      type: 'hidden',
                                      url: '/knowledgebase/courses/:id'
                                  },
                                  {
                                      id: 'kb-acknowledgement-timeline',
                                      title: 'Read Timeline',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/acknowledgement-timeline'
                                  },
                                  {
                                      id: 'kb-acknowledgement-leaderboard',
                                      title: 'Leaderboard',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/acknowledgement-leaderboard'
                                  },
                                  {
                                      id: 'kb-quiz-acknowledgement-timeline',
                                      title: 'Quiz Response Timeline',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/quiz-acknowledgement-timeline'
                                  },
                                  {
                                      id: 'kb-courses-create',
                                      title: 'Create Course',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: '/knowledgebase/courses/create'
                                  }
                              ]
                          },

                          {
                              id: 'layout-purchase',
                              title: 'Purchase Requests',
                              type: ActiveApps?.some((app) => app.app_name === 'purchase_request') && 'collapse',
                              icon: 'feather icon-shopping-cart',
                              url: '/purchase-requests',
                              children: [
                                  {
                                      id: 'purchase-create',
                                      title: 'Create New Purchase Request',
                                      type: 'item',
                                      url: '/purchase-requests/create'
                                  },
                                  {
                                      id: 'purchase',
                                      title: 'All Purchase Requests',
                                      type: 'item',
                                      url: '/purchase-requests'
                                  },
                                  {
                                      id: 'purchase-view',
                                      title: 'View Purchase',
                                      type: 'hidden',
                                      url: '/purchase-requests/details/:id'
                                  }
                                  //   {
                                  //     id: 'purchase-final-approval',
                                  //     title: 'Final Approval',
                                  //     type: isPRAprover ? 'item': 'hidden',
                                  //     url: '/purchase-requests/create'
                                  // },
                              ]
                          },
                          {
                              id: 'check_list',
                              title: 'Check List',
                              icon: 'fa fa-tasks',
                              type: ActiveApps?.some((app) => app.app_name === 'checklist') && 'collapse',
                              url: '/checklist/templates',
                              children: [
                                  {
                                      id: 'create-template',
                                      title: 'Create Template',
                                      type: isChecklistAdmin ? 'item' : 'hidden',
                                      icon: 'feather icon-file-plus',
                                      classes: 'nav-item',
                                      url: '/checklist/template/create'
                                  },
                                  {
                                      id: 'checklist-template',
                                      title: 'CheckLists',
                                      type: 'item',
                                      icon: 'feather icon-list',
                                      classes: 'nav-item',
                                      url: '/checklist/templates'
                                  },
                                  {
                                      id: 'assigned-checklists',
                                      title: 'Assigned Checklists',
                                      type: 'item',
                                      icon: 'fa fa-clipboard-check',
                                      classes: 'nav-item',
                                      url: '/checklist/checklists/'
                                  },
                                  {
                                      id: 'checklists-view',
                                      title: 'Checklist',
                                      type: 'hidden',
                                      url: '/checklist/checklists/:id'
                                  },
                                  {
                                      id: 'edit-checklists-view',
                                      title: 'Edit Checklist',
                                      type: 'hidden',
                                      url: '/checklist/create/:id'
                                  }
                              ]
                          },
                          {
                              id: 'settings-menu',
                              title: 'Settings',
                              type: user && Object.values(userRoles ?? {}).some((role) => role === true) ? 'collapse' : '',
                              icon: 'feather icon-settings',
                              url: `${settingsPageRoute}`,
                              children: [
                                  {
                                      id: 'company_profile',
                                      title: 'Company Profile',
                                      type: 'item',
                                      url: `${settingsPageRoute}#company-profile`
                                  },
                                  {
                                      id: 'password',
                                      title: 'Change Password',
                                      type: 'item',
                                      url: `${settingsPageRoute}#change-password`
                                  },
                                  {
                                      id: 'motto',
                                      title: 'Company Moto',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#motto`
                                  },
                                  {
                                      id: 'attendance',
                                      title: 'Time Clock Settings',
                                      type: isAttendanceAdmin && ActiveApps.includes('attendance') ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#attendance`
                                  },
                                  {
                                      id: 'employees_admin',
                                      title: 'Employees Admin',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#employees_admin`
                                  },
                                  {
                                      id: 'company_divisions',
                                      title: 'Company Divisions/Depts',
                                      type: isProfileAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#company_divisions`
                                  },
                                  {
                                      id: 'menu-links',
                                      title: 'Additional Menu Links',
                                      type: isTotalAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#menu-links`
                                  },
                                  {
                                      id: 'request-settings',
                                      title: 'Request Settings',
                                      type: isRequestAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#request-settings`
                                  },
                                  {
                                      id: 'knowledgebase-settings',
                                      title: 'Knowledgebase Settings',
                                      type: isKBAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#knowledgebase-settings`
                                  },
                                  {
                                      id: 'system-pref',
                                      title: 'System Preferences',
                                      type: isTotalAdmin ? 'item' : 'hidden',
                                      url: `${settingsPageRoute}#system-pref`
                                  }
                              ]
                          },

                          {
                              id: 'help',
                              title: 'Get Help',
                              type: 'item',
                              icon: 'feather icon-help-circle',
                              classes: 'nav-item',
                              url: 'https://help.humanagement.io/',
                              target: true,
                              external: true
                          },
                          {
                              id: 'Feedback',
                              title: 'Feedback/Roadmap',
                              type: 'item',
                              icon: 'feather icon-map',
                              classes: 'nav-item',
                              url: 'https://humanagement.canny.io',
                              target: true,
                              external: true
                          }

                          //
                          // {
                          //     id: 'documentation',
                          //     title: 'Documentation',
                          //     type: 'item',
                          //     icon: 'feather icon-book',
                          //     classes: 'nav-item',
                          //     url: 'http://html.codedthemes.com/datta-able/react/docs',
                          //     target: true,
                          //     external: true
                          // }
                      ]
                  }
              ]
          };
};

export default getMenuItems;
