import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import knowledgeService from '../../../services/knowledgeService';
import { dictHasItems } from '../../../utils/common';
import CustomTooltip from '../../CustomTooltip';
import DeleteCategoryModal from '../Categories/Modals/DeleteCategoryModal';

export default function CategoryForm({ instance, onSubmitSuccess, showDeleteButton, handleDeleteSuccess, loadedCategories }) {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const company = useSelector((state) => state.account.user?.company?.id);

    const Schema = Yup.object().shape({
        category: Yup.string().required('This field is required')
    });

    const getArticleCategories = async () => {
        if (loadedCategories) {
            setCategories(loadedCategories);
            return;
        }
        setLoading(true);
        try {
            const data = await knowledgeService.getArticleCategories({ paginate: false, simple: true });
            data?.sort((a, b) => a.category.localeCompare(b.category));
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getArticleCategories();
    }, []);

    // if we are updating, remove the current category from the list of categories
    let filteredCategories = categories.filter((category) => category.id !== instance?.id);

    function renderCategoryOptions(category, prefix = '') {
        return (
            <>
                <option key={category.id} id={category.id} value={category.id}>
                    {prefix}
                    {category.category}
                </option>
                {category.subcategories?.map((subCategory) => renderCategoryOptions(subCategory, `${prefix}${category.category} < `))}
            </>
        );
    }

    const onSubmit = async (formData, actions) => {
        // send the created card data to the backend
        let data;
        if (instance) {
            // we are updating
            data = await knowledgeService.patchArticleCategories(instance?.id, formData);
        } else {
            // we are creating
            data = await knowledgeService.postArticleCategories({ ...formData, company });
        }

        if (data) {
            // call the callback function if it exists
            typeof onSubmitSuccess === 'function' && (await onSubmitSuccess(data));
        }
        // update submit to false
        actions.setSubmitting(false);
    };

    return (
        <Formik
            validationSchema={Schema}
            // validate={validate}
            initialValues={{
                category: instance?.category || instance?.name || '',
                parent: instance?.parent || '',
                is_subsection: instance?.is_subsection || false
            }}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ errors, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="category">Category Name</label>
                        <Field className="form-control" name="category" type="text" placeholder="Enter category name..." />
                        {errors.category && <span className="text-danger">{errors.category}</span>}
                    </div>

                    <CustomTooltip title="This nests this category and any of it's articles under the selected parent category in the knowledge base and course selection views">
                        <div className="form-group">
                            <label htmlFor="parent">Parent Category</label>
                            <Field className="form-control" name="parent" as="select">
                                {loading ? (
                                    <option disabled={true} value="">
                                        Loading...
                                    </option>
                                ) : filteredCategories.length > 0 ? (
                                    <>
                                        <option key="none" id="none" value="">
                                            NONE
                                        </option>
                                        {filteredCategories.map((category) => renderCategoryOptions(category))}
                                    </>
                                ) : (
                                    <option id="PLACEHOLDER" disabled>
                                        Please Add Your First Category
                                    </option>
                                )}
                            </Field>
                            {errors.parent && <span className="text-danger">{errors.parent}</span>}
                        </div>
                    </CustomTooltip>

                    <div className="form-check">
                        <Field className="form-check-input" name="is_subsection" type="checkbox" />
                        <label className="form-check-label" htmlFor="is_subsection">
                            Subsection
                        </label>
                    </div>

                    <div>
                        {errors.is_subsection && <span className="text-danger">{errors.is_subsection}</span>}
                        <small className="text-muted">
                            Check this box if this category is a subsection. The category contents will be opened in a new link in articles
                            list if selected. Great for a much cleaner look with large categories.
                        </small>
                    </div>
                    <Modal.Footer>
                        {showDeleteButton && (
                            <DeleteCategoryModal
                                categoryId={instance?.id}
                                categoryName={instance?.category}
                                onDeleteSuccess={handleDeleteSuccess}
                            />
                        )}
                        <Button type="submit" className="mt-0 mr-0" disabled={isSubmitting || dictHasItems(errors)}>
                            {isSubmitting ? 'Saving....' : 'Save'}
                        </Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    );
}
