import React from 'react';
import CustomTooltip from '../CustomTooltip';
import ViewFileModal from '../ViewFileModal';

export default function DisplayFiles({ uploadedFiles, setUploadedFiles, callbackFunc, disableDelete }) {
    const getFileName = (url) => {
        const fileName = url.split('/').pop();
        return fileName.replace(/[_-]|%20/g, ' ');
    };

    const handleDeleteFile = (index) => {
        const newFiles = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles(newFiles);
        typeof callbackFunc === 'function' && callbackFunc(newFiles);
    };

    return (
        <div className="uploaded-files mb-3">
            <ul className="list-group">
                {uploadedFiles.map((fileUrl, index) => (
                    console.log(fileUrl),
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                            {getFileName(fileUrl)}
                        </a>
                        
                        <span style={{ display: 'flex' }}>
                            <CustomTooltip title={'View Document'}>
                                <ViewFileModal fileUrl={fileUrl} icon={<i className="feather icon-eye" />} className='btn btn-sm btn-none m-0' />
                            </CustomTooltip>
                            <CustomTooltip title={'Download Document'}>
                                <a target="_blank" rel="noopener noreferrer" href={fileUrl} className="btn btn-sm btn-none mb-n2 p-0 m-0" download>
                                    <i className="fa fa-fw fa-download fa-md" />
                                </a>
                            </CustomTooltip>
                            {!disableDelete && (
                                <CustomTooltip title={'Delete Document'}>
                                    <button 
                                        type="button" 
                                        className="btn btn-sm btn-none m-0" 
                                        onClick={() => handleDeleteFile(index)}
                                >
                                    <i className="feather icon-trash"></i>
                                </button>
                            </CustomTooltip>)}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}