import React, { useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import {
  BigPlayButton,
  ClosedCaptionButton,
  ControlBar,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl
} from 'video-react';
import '../../../assets/css/video-react.css'; // import css

const VideoPlayer = forwardRef(({ videoUrl, posterUrl, transcript, companyLogo }, ref) => {
  const playerRef = useRef(null);
  
  useEffect(() => {
    console.log("VideoPlayer mounted. Player ref:", playerRef.current);
  }, []);
  
  // Expose player methods to parent components
  useImperativeHandle(ref, () => ({
    play: () => {
      console.log("play() called");
      if (playerRef.current) {
        playerRef.current.actions.play();
      }
    },
    pause: () => {
      console.log("pause() called");
      if (playerRef.current) {
        playerRef.current.actions.pause();
      }
    },
    seek: (time) => {
      console.log("seek() called with time:", time);
      if (playerRef.current) {
        playerRef.current.actions.seek(time);
      }
    },
    subscribeToStateChange: (callback) => {
      console.log("subscribeToStateChange() called");
      if (playerRef.current) {
        return playerRef.current.subscribeToStateChange(callback);
      }
    },
    getState: () => {
      if (playerRef.current) {
        return playerRef.current.getState();
      }
      return null;
    }
  }), []);

  return (
    <div className="w-100">
      <Player
        ref={playerRef}
        className="w-100"
        controls
        src={videoUrl}
        poster={posterUrl}
      >
        {transcript && (
          <track
            kind="subtitles"
            src={URL.createObjectURL(
              new Blob(
                [
                  transcript.replace(/\\n/g, '\n') // Replace \n with actual line breaks
                ],
                { type: 'text/vtt' }
              )
            )}
            srcLang="en"
            label="English"
            style={{
              zIndex: 100,
              bottom: 10,
              position: 'absolute'
            }}
            default
          />
        )}
        {companyLogo && (
          <div
            className="logo"
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              maxWidth: 130,
              zIndex: 1
            }}
          >
            <img 
              src={companyLogo} 
              alt="Logo" 
              style={{ 
                maxHeight: 40, 
                maxWidth: 130, 
                width: 'auto', 
                height: 'auto', 
                objectFit: 'contain' 
              }} 
            />
          </div>
        )}
        <BigPlayButton position="center" />
        <ControlBar autoHide={true} autoHideTime={1000} style={{ zIndex: 100 }} zIndex={100}>
          <ReplayControl seconds={10} order={2.2} />
          <ForwardControl seconds={10} order={3.2} />
          <PlaybackRateMenuButton rates={[2, 1.7, 1.5, 1.2, 1, 0.7, 0.5]} />
          <ClosedCaptionButton order={7.1} />
        </ControlBar>
      </Player>
    </div>
  );
});

export default VideoPlayer;
