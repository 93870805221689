import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createPosition } from '../../services/userService';
import { SET_POSITIONS, UPDATE_ORG_CHART, UPDATE_ORG_CHART_POSITION } from '../../store/actions';
import { sortListOfObjectsAlphabetically } from '../../utils/common';
import CreatePositionForm from './CreatePositionForm';

export default function CreatePositionModal({ className, callbackFunc, type }) {
    const [showCreatePositionModal, setShowCreatePositionModal] = useState(false);
    const dispatch = useDispatch();
    const positions = useSelector((state) => state.account.positions);
    const updateOrgChart = useSelector((state) => state.account.updateOrgChart);

    const handleCloseCreatePositionModal = () => setShowCreatePositionModal(false);
    const handleShowCreatePositionModal = () => setShowCreatePositionModal(true);

    const handleSubmit = async (formData) => {
        const res_data = await createPosition(formData);

        if (res_data) {
            // Add the new positions to the existing ones and sort by name
            const newPositions = [res_data, ...positions];

            const sortedPositions = sortListOfObjectsAlphabetically(newPositions, 'name');
            dispatch({ type: SET_POSITIONS, payload: sortedPositions });
            dispatch({ type: UPDATE_ORG_CHART_POSITION, payload: res_data })
            // dispatch({ type: UPDATE_ORG_CHART, payload: !updateOrgChart });

            handleCloseCreatePositionModal();
            if (typeof callbackFunc === 'function') {
                callbackFunc(res_data);
            }
            return res_data;
        }
        return false;
    };

    return (
        <>
            {type === 'simple' ? (
                <Button className={`btn btn-sm btn-icon ml-2 ${className}`} onClick={handleShowCreatePositionModal}>
                    <i className="feather icon-plus fa-lg mx-0"> </i>
                </Button>
            ) : (
                <Button variant="primary" className={className} onClick={handleShowCreatePositionModal}>
                    Create Position
                </Button>
            )}

            <Modal backdrop="static" show={showCreatePositionModal} onHide={handleCloseCreatePositionModal} id="create-position-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Create Position</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatePositionForm handleSubmit={handleSubmit} type={type} />
                </Modal.Body>
            </Modal>
        </>
    );
}
